import React, { useContext, useEffect, useState } from "react";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionPromo1 from "components/SectionPromo1";
import { Helmet } from "react-helmet";
import SectionHero2 from "components/SectionHero/SectionHero2";
import SectionSliderLargeProduct from "components/SectionSliderLargeProduct";
import SectionSliderProductCard from "components/SectionSliderProductCard";
import SectionSliderProductCardNew from "components/SectionSliderProductCardNew";
import DiscoverMoreSlider from "components/DiscoverMoreSlider";
import SectionGridMoreExplore from "components/SectionGridMoreExplore/SectionGridMoreExplore";
import SectionPromo2 from "components/SectionPromo2";
import SectionPromo from "components/SectionPromo1";
import SectionSliderCategories from "components/SectionSliderCategories/SectionSliderCategories2";
import SectionSliderReviews from "components/SectionSliderCategories/SectionSliderReviews";
import SectionSliderEvents from "components/SectionSliderCategories/SectionSliderEvents";
import SectionSliderCompanies from "components/SectionSliderCategories/SectionSliderCompanies";
import SectionSliderJobs from "components/SectionSliderCategories/SectionSliderJobs";
import SectionSliderMarketPlace from "components/SectionSliderCategories/SectionSliderMarketPlace";
import SectionSliderMembers from "components/SectionSliderCategories/SectionSliderMembers";
import SectionSliderProviders from "components/SectionSliderCategories/SectionSliderProviders";
import SectionSliderCategoriesNew from "components/SectionSliderCategories/SectionSliderCategoriesNew";
import SectionGridFeatureItems from "./SectionGridFeatureItems";
import SectionGridFeatureItemsNew from "./SectionGridFeatureItemsNew";
import SectionPromo3 from "components/SectionPromo3";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import SectionMagazine5 from "containers/BlogPage/SectionMagazine5";
import Heading from "components/Heading/Heading";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { PRODUCTS, SPORT_PRODUCTS } from "data/data";
import {
  Categories,
  CategoriesPopular,
  GetMaincategory,
  GetSiteReviews,
  GetTopSelling,
  getAllProducts,
  getContracts,
  getJobs,
  getWholeSalers
} from "Services/API/Get";
import department1Png from "images/collections/department1.png";
import department2Png from "images/collections/department2.png";
import department3Png from "images/collections/department3.png";
import department4Png from "images/collections/department4.png";
import { useHistory, useLocation } from "react-router-dom";
import { addToast } from "shared/Toast";
import { setSiteVisits } from "Services/API/Put";
import _ from "lodash";
import category1 from "images/solutions/1.png";
import category2 from "images/solutions/2.png";
import category3 from "images/solutions/3.png";
import category4 from "images/solutions/4.png";
import category5 from "images/solutions/5.png";
import category6 from "images/solutions/6.png";
import category7 from "images/solutions/7.png";

import HomeSection from "components/SectionSliderCategories/HomeSection";
import HomeSectionWhy from "components/SectionSliderCategories/HomeSectionWhy";
import HomeSectionShoppers from "components/SectionSliderCategories/HomeSectionShoppers";
import HomeSectionTrade from "components/SectionSliderCategories/HomeSectionTrade";
import HomeSectionFAQS from "components/SectionSliderCategories/HomeSectionFAQS";
import HomeSectionSellers from "components/SectionSliderCategories/HomeSectionSellers";
import HomeSectionPreffered from "components/SectionSliderCategories/HomeSectionPreffered";
import HomeSectionUncover from "components/SectionSliderCategories/HomeSectionUncover";

import SectionSliderContracts from "components/SectionSliderCategories/SectionSliderContracts";
import AuthContext from "Context/AuthContext";
import { FONTS_INTER } from "Constant";
import { COLORS } from "Constant/Colors";

import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import { Typography } from "@mui/material";
import ModalQuickView from "components/ModalToast";

import TabFilters from "containers/TabFiltersNew2";
import { useTranslation } from "react-i18next";
import WishContext from "Context/WishContext";
import LoadingSpinner from "shared/LoadingSpinner";
import SectionSliderSucessStories from "components/SectionSliderCategories/SectionSliderSucessStories";

const DATA = [
  {
    name: "BROOMS & DUSTPANS",
    desc: "20+ categories",
    img: department1Png,
    color: "bg-indigo-100"
  },
  {
    name: "BRUSHWARE",
    desc: "10+ categories",
    img: department2Png,
    color: "bg-slate-100"
  },
  {
    name: "BUCKETS",
    desc: "34+ categories",
    img: department3Png,
    color: "bg-sky-100"
  },
  {
    name: "CARTS & TROLLEYS",
    desc: "12+ categories",
    img: department4Png,
    color: "bg-orange-100"
  },
  {
    name: "CHEMICALS",
    desc: "20+ categories",
    img: department1Png,
    color: "bg-indigo-100"
  },
  {
    name: "DUST CONTROL",
    desc: "10+ categories",
    img: department2Png,
    color: "bg-slate-100"
  },
  {
    name: "HARD FLOOR CARE & PADS",
    desc: "34+ categories",
    img: department3Png,
    color: "bg-sky-100"
  },
  {
    name: "GARBAGE BAGS & BINS",
    desc: "12+ categories",
    img: department4Png,
    color: "bg-orange-100"
  },
  {
    name: "MACHINERY",
    desc: "20+ categories",
    img: department1Png,
    color: "bg-indigo-100"
  },
  {
    name: "MATTING",
    desc: "10+ categories",
    img: department2Png,
    color: "bg-slate-100"
  },
  {
    name: "MOPS & POLISH SPREADERS",
    desc: "34+ categories",
    img: department3Png,
    color: "bg-sky-100"
  },
  {
    name: "SAFETY",
    desc: "12+ categories",
    img: department4Png,
    color: "bg-orange-100"
  },
  {
    name: "VACUUMS & PARTS",
    desc: "20+ categories",
    img: department1Png,
    color: "bg-indigo-100"
  },
  {
    name: "WASHROOM SUPPLIES",
    desc: "10+ categories",
    img: department2Png,
    color: "bg-slate-100"
  },
  {
    name: "WINDOW CLEANING",
    desc: "34+ categories",
    img: department3Png,
    color: "bg-sky-100"
  },
  {
    name: "WIPES, CLOTHS & MORE",
    desc: "12+ categories",
    img: department4Png,
    color: "bg-orange-100"
  }
];

const DATA2 = [
  {
    name: "AGED & HEALTH CARE",
    desc: "20+ categories",
    image: category1,
    color: "bg-indigo-100"
  },
  {
    name: "EDUCATION",
    desc: "10+ categories",
    image: category2,
    color: "bg-slate-100"
  },
  {
    name: "FOOD & DAIRY MANUFACTURING",
    desc: "34+ categories",
    image: category7,
    color: "bg-sky-100"
  },
  {
    name: "HOSPITALITY",
    desc: "12+ categories",
    image: category3,
    color: "bg-orange-100"
  },
  {
    name: "INDUSTRIAL & WAREHOUSE",
    desc: "20+ categories",
    image: category5,
    color: "bg-indigo-100"
  },
  {
    name: "OFFICE & RETAIL",
    desc: "10+ categories",
    image: category4,
    color: "bg-slate-100"
  },
  {
    name: "WINDOW CLEANING",
    desc: "34+ categories",
    image: category6,
    color: "bg-sky-100"
  }
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "white",
  boxShadow: 24,
  p: 4,
  maxWidth: 400,
  border: 0
};
const theme = createTheme({
  palette: {
    primary: {
      main: COLORS.Primary
    },
    secondary: {
      main: "#f44336"
    }
  }
});

function PageHome() {
  const { t } = useTranslation();

  const { lan, setLan } = useContext(WishContext);

  const history = useHistory();

  const useQuery = () => {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  };

  let query = useQuery();

  const [allProducts4, setallProducts4] = React.useState([]);
  const [allProducts, setallProducts] = React.useState([]);
  const [allProducts2, setallProducts2] = React.useState([]);
  const [cKey, setcKey] = React.useState("all");
  const [allProducts5, setallProducts5] = React.useState<any>([]);

  const [sellers, setsellers] = React.useState([]);
  const [contracts, setcontracts] = React.useState([]);
  const [reviews, setreviews] = React.useState([]);
  const [providers, setproviders] = React.useState([]);
  const [jobs, setjobs] = React.useState([]);
  const [popular, setpopular] = React.useState([]);

  const { user, setUser } = useContext(AuthContext);
  const [openSuccess, setopenSuccess] = React.useState(false);

  const [tabNo, settabNo] = React.useState(1);

  const [nProducts, setnProducts] = useState([]);
  const [allSizes, setallSizes] = useState<any | null>([]);
  const [allColours, setallColours] = useState<any | null>([]);
  const [allSubs, setallSubs] = useState<any | null>([]);
  const [categories, setcategories] = useState([]);
  const [tags, settags] = useState<any | null>([]);
  const [selectedSize, setselectedSize] = useState("");
  const [selectedColour, setselectedColour] = useState("");
  const [selectedCat, setselectedCat] = useState<any | null>("");
  const [selectedSubCat, setselectedSubCat] = useState<any | null>("");

  const [minPrice, setminPrice] = useState<any | null>(0);
  const [maxPrice, setmaxPrice] = useState<any | null>(999);
  const [selectedSub, setselectedSub] = useState<any>("");

  // const [selectedSubCat, setselectedSubCat] = useState([]);
  const [dataCount, setcount] = useState(0);
  const [search, setsearch] = useState("");
  const [lopen, setlopen] = useState(true);

  const [mainDesc, setmainDesc] = useState<any>("");
  const [subDesc, setsubDesc] = useState("");
  const [filterSortValue, setFilterSortValue] = useState("");
  const [filterSortIndex, setfilterSortIndex] = useState(0);

  const [pageLimit, setpageLimit] = useState(
    window.matchMedia("(min-width: 1296px)").matches ? 25 : 24
  );
  const [currentPage, setCurrentPage] = useState(1);

  const [showModalQuickView, setShowModalQuickView] = useState(false);

  const [selectedPro, setselectedPro] = useState<any | null>([]);

  const [homeDistrict, sethomeDistrict] = useState("");
  const [homeCity, sethomeCity] = useState("");

  const [selectedAdds, setselectedAdds] = useState([]);

  const [country, setcountry] = useState("");

  const [selectedAge, setselectedAge] = useState<any | null>([]);
  const [selectedGender, setselectedGender] = useState<any | null>([]);
  const [selectedCast, setselectedCast] = useState<any | null>([]);
  const [selectedJob, setselectedJob] = useState<any | null>([]);
  const [selectedHorr, setselectedHorr] = useState<any | null>([]);
  const [selectedRace, setselectedRace] = useState<any | null>([]);
  const [selectedRel, setselectedRel] = useState<any | null>([]);
  const [selectedEdu, setselectedEdu] = useState<any | null>([]);
  const [selectedMarr, setselectedMarr] = useState<any | null>([]);

  const [selectedLan, setselectedLan] = useState("");

  const [height, setheight] = useState<any | null>("");

  useEffect(() => {
    setselectedLan(t("langKey"));
  }, [t("langKey")]);

  React.useEffect(() => {
    let pQuery = query.get("pricing");

    let sQuery = query.get("payment");

    // if (pQuery === "true") {
    //   // setlOpen(true);
    //   // setTimeout(() => {
    //   // }, 2000);
    //   scrollToElement2();
    //   setlOpen(false);
    //   history.push("/home");
    //   // scrollToElement2();
    // }

    if (sQuery === "true") {
      // setlOpen(true);
      // setTimeout(() => {
      // }, 2000);
      // setSteps(6);
      localStorage.setItem("order", "true");
      setopenSuccess(true);
      // addToast("Your order has been placed successfully !", "success", 8000);
      history.push("/");
      // scrollToElement2();
    }
  }, []);

  const getNewArrival2 = async () => {
    await getAllProducts({
      pto: Number.MAX_SAFE_INTEGER,
      pfrom: 0,
      sort: {
        sortField: "discount",
        sort: "desc"
      },
      pNo: "1",
      //   limit: sCategory !== "all" ? "16" : Number.MAX_SAFE_INTEGER.toString(),
      limit: "16",
      latest: "",
      userId: "",
      hire: "0",
      // latest: 1,

      manufacturer: JSON.stringify([])
      //   categories: sCategory !== "" && sCategory !== "all" ? JSON.stringify([sCategory]) : JSON.stringify([]),
    })
      .then((res) => {
        console.log("this is from discount", res.data.result);

        if (res?.data?.result?.length !== 0) {
          setallProducts4(res.data.result);
          // this.setState(
          //   {
          //     array: res.data.result,
          //     showOff: true,
          //   },
          //   () => console.log("this is from new arrival after", this.state.array)
          // );
        }
      })
      .catch((error) => {
        console.error(">errorrrrr>", error);
        setallProducts4([]);
        // this.setState({
        //   array: [],
        //   showOff: false,
        // });
      });
  };

  const getNewArrival3 = async () => {
    await getAllProducts({
      pto: Number.MAX_SAFE_INTEGER,
      pfrom: 0,
      sort: {
        sortField: "soldcount",
        sort: "desc"
      },
      pNo: "1",
      //   limit: sCategory !== "all" ? "16" : Number.MAX_SAFE_INTEGER.toString(),
      limit: "16",
      featured: "",
      userId: "",
      hire: "0",
      // latest: 1,

      manufacturer: JSON.stringify([])
      //   categories: sCategory !== "" && sCategory !== "all" ? JSON.stringify([sCategory]) : JSON.stringify([]),
    })
      .then((res) => {
        console.log("this is from bestselling", res.data.result);

        if (res?.data?.result?.length !== 0) {
          setallProducts2(res.data.result);
          // this.setState(
          //   {
          //     array: res.data.result,
          //     showOff: true,
          //   },
          //   () => console.log("this is from new arrival after", this.state.array)
          // );
        }
      })
      .catch((error) => {
        console.error(">errorrrrr>", error);
        setallProducts2([]);
        // this.setState({
        //   array: [],
        //   showOff: false,
        // });
      });
  };

  const getNewArrival = async () => {
    await getAllProducts({
      pto: Number.MAX_SAFE_INTEGER,
      pfrom: 0,
      sort: {
        sortField: "productId",
        sort: "asc"
      },
      pNo: "1",
      limit: "12",
      // latest: 1,

      manufacturer: JSON.stringify([]),
      categories:
        cKey !== "" && cKey !== "all"
          ? JSON.stringify([cKey])
          : JSON.stringify([])
    })
      .then((res) => {
        console.log("this is from new arrival before2222", res.data.result);

        if (res?.data?.result?.length !== 0) {
          setallProducts(res.data.result);
          // this.setState(
          //   {
          //     array: res.data.result,
          //     showOff: true,
          //   },
          //   () => console.log("this is from new arrival after", this.state.array)
          // );
        }
      })
      .catch((error) => {
        console.error(">errorrrrr>", error);
        setallProducts([]);
        // this.setState({
        //   array: [],
        //   showOff: false,
        // });
      });
  };

  const getNewArrival5 = async () => {
    Categories()
      .then(async (response) => {
        console.log("category>>>>", response?.data);

        var cats = response?.data?.filter(function (el: any) {
          return el.maincategory === "Industry Solutions";
        });

        console.log("CATS>>>", cats);

        const selectedCat = cats[0].mainId;

        const ob = {
          sort: {
            sortField: "productName",
            sort: "asc"
          },
          categories: JSON.stringify([selectedCat]),
          distinct: "0"
        };

        await getAllProducts(ob).then((res) => {
          console.log("FFF>>", res);
          const allColors = _.uniqBy(
            res?.data?.result,
            function (e: { clolour: any }) {
              return e.clolour;
            }
          );
          var allColors2 = allColors?.filter(function (el: any) {
            return el.clolour !== "";
          });
          const allSizes = _.uniqBy(
            res?.data?.result,
            function (e: { size: any }) {
              return e.size;
            }
          );
          var sizeArr = allSizes?.filter(function (el: { size: string }) {
            return el.size !== "";
          });
          const allPrice = _.uniqBy(
            res?.data?.result,
            function (e: { discountedprice: any }) {
              return e.discountedprice;
            }
          );
          const allTags = _.uniqBy(
            res?.data?.result,
            function (e: { tags: any }) {
              return e.tags;
            }
          );
          const allSubs = _.uniqBy(
            res?.data?.result,
            function (e: { subcatId: any; subCategory: any; subcatDesc: any }) {
              return e.subCategory;
            }
          );

          console.log("WWEDSD", allSubs);

          var subsArr = allSubs?.filter(function (el: { subCategory: null }) {
            return el.subCategory !== null;
          });
          const myPrices = _.map(allPrice, "discountedprice");

          const Pricelist = _.sortBy(myPrices, [
            function (o: any) {
              return o;
            }
          ]);

          const mySizes = _.map(sizeArr, "size");
          const myColors = _.map(allColors2, "clolour");
          const myTags = _.map(allTags, "tags");
          const mySubs = _.map(subsArr, "subCategory");

          const updateMyColors = myColors?.map((item: any, index: any) => {
            return {
              id: index,
              name: item,
              status: false
            };
          });

          const updateMySubs = subsArr.map((item, index) => {
            let img = "";
            switch (`${item?.subCategory}`.trim().toUpperCase()) {
              case "AGED & HEALTH CARE":
                img = category1;
                break;
              case "EDUCATION":
                img = category2;
                break;
              case "FOOD & DAIRY MANUFACTURING":
                img = category7;
                break;
              case "HOSPITALITY":
                img = category3;
                break;
              case "INDUSTRIAL & WAREHOUSE":
                img = category5;
                break;
              case "OFFICE & RETAIL":
                img = category4;
                break;
              case "WINDOW CLEANING":
                img = category6;
                break;

              // case :
              //   img = category1;
              //   break;
              // case :
              //   img = category1;
              //   break;
            }

            return {
              id: item.subcatId,
              name: item.subCategory,
              mainId: selectedCat,
              subcatId: item.subcatId,
              image: img
              // description: item.subcatDesc,
            };
          });
          console.log("subs>>>>>", updateMySubs);
          // setallSizes(mySizes);
          // setallColours(myColors);
          // settags(myTags);
          // setallSubs(updateMySubs);

          setallProducts5(updateMySubs);

          // this.setState({
          //   color: updateMyColors,
          //   size: mySizes,
          //   min: Pricelist[0],
          //   max: Pricelist[Pricelist.length - 1],
          //   range: {
          //     min: Pricelist[0],
          //     max: Pricelist[Pricelist.length - 1],
          //   },
          // });
        });
      })
      .catch(() => {});
  };

  React.useEffect(() => {
    if (cKey !== "all") {
      console.log("VVCBF>>>", cKey);
      // getNewArrival();
    }
  }, [cKey]);

  const TopSellers = async () => {
    await GetTopSelling({
      limit: "5"
    })
      .then(async (response) => {
        console.log("top selling>>>>", response?.data);

        setsellers(response.data);
      })
      .catch(() => {
        setsellers([]);
      });
  };

  const Contracts = async () => {
    await getContracts({
      limit: "5",
      userId: "",
      isActive: "1"
    })
      .then(async (response) => {
        console.log("contracts>>>>", response?.data);

        setcontracts(response.data.result);
      })
      .catch(() => {
        setcontracts([]);
      });
  };

  const Reviews = async () => {
    await GetSiteReviews({
      limit: "5"
    })
      .then(async (response) => {
        console.log("reviews>>>>", response?.data);

        setreviews(response.data);
      })
      .catch(() => {
        setreviews([]);
      });
  };

  const ServiceProviders = async () => {
    setlopen(true);

    await getWholeSalers({
      userType: JSON.stringify([3]),
      limit: pageLimit.toString(),
      userId: "",
      mainId: selectedCat ? JSON.stringify([selectedCat]) : JSON.stringify([]),
      subId: selectedSubCat
        ? JSON.stringify([selectedSubCat])
        : JSON.stringify([])
    })
      .then(async (response) => {
        console.log("sproviders>>>>", response?.data);

        setproviders(response.data.result);
        setlopen(false);
      })
      .catch(() => {
        setproviders([]);
        setlopen(false);
      });
  };

  const Jobs = async () => {
    setlopen(true);
    await getJobs({
      jobType: JSON.stringify([]),
      userId: "",
      title: "",
      // categories: selectedCat === "" ? JSON.stringify([]) : JSON.stringify([selectedCat]),
      // fromHourlyRate: minPrice,
      // toHourlyRate: maxPrice,
      page: "1",
      limit: pageLimit.toString(),
      state: homeDistrict,
      suburb: homeCity,
      country: country,
      sortValue:
        filterSortValue === "LowToHigh"
          ? "asc"
          : filterSortValue === "HighToLow"
          ? "desc"
          : filterSortValue === "latest"
          ? "desc"
          : filterSortValue === "older"
          ? "asc"
          : "desc",
      sortField:
        filterSortValue === "LowToHigh"
          ? "hourlyRate"
          : filterSortValue === "HighToLow"
          ? "hourlyRate"
          : filterSortValue === "latest"
          ? "id"
          : filterSortValue === "older"
          ? "id"
          : "id",
      isActive: "1",
      gender: JSON.stringify(selectedGender),
      age: JSON.stringify(selectedAge),
      cast: JSON.stringify(selectedCast),
      education: JSON.stringify(selectedEdu),
      horroscope: JSON.stringify(selectedHorr),
      marriage: JSON.stringify(selectedMarr),
      occupation: JSON.stringify(selectedJob),
      race: JSON.stringify(selectedRace),
      religion: JSON.stringify(selectedRel),
      language: selectedLan
    })
      .then(async (response) => {
        console.log("jobshome>>>>", response?.data.result);

        setjobs(response.data.result);
        setlopen(false);
      })
      .catch((err) => {
        console.log("jobshomeerr>>>>", err);
        setjobs([]);
        setlopen(false);
      });
  };

  const PopularCat = async () => {
    await GetMaincategory({
      marketPlace: ""
    })
      .then(async (response) => {
        console.log("category>>>>", response?.data);
        setpopular(response.data);
      })
      .catch(() => {
        setpopular([]);
      });
  };

  const getDetails = async () => {
    const fetchData = async () => {
      // await PopularCat();

      // await getNewArrival2();
      // // const data2 = await getNewArrival();
      // await getNewArrival3();

      // await TopSellers();

      // await Contracts();

      await Jobs();

      await ServiceProviders();

      // const data4 = await getNewArrival5();
    };

    // call the function
    fetchData();
  };

  useEffect(() => {
    Reviews();
  }, []);

  React.useEffect(() => {
    console.log(">>hello");
    getDetails();
  }, [
    pageLimit,
    tabNo,
    lan,
    selectedLan,
    homeDistrict,
    homeCity,
    country,
    filterSortValue,
    filterSortValue,
    selectedGender,
    selectedAge,
    selectedCast,
    selectedEdu,
    selectedHorr,
    selectedMarr,
    selectedJob,
    selectedRace,
    selectedRel,
    selectedCat,
    selectedSubCat
  ]);

  React.useEffect(() => {
    setselectedAge([]);
    setselectedGender([]);
    setselectedCast([]);
    setselectedJob([]);
    setselectedHorr([]);
    setselectedRace([]);
    setselectedRel([]);
    setselectedEdu([]);
    setselectedMarr([]);
    setselectedCat("");
    setselectedSubCat("");
    sethomeCity("");
    sethomeDistrict("");
    setcountry("");
  }, [tabNo, lan]);

  return (
    <>
      <div className="nc-PageHome relative overflow-hidden w-full">
        <Helmet>
          <title>Home || Mangalya.lk</title>
        </Helmet>

        <SectionHero2 tabNo={tabNo} settabNo={settabNo} />

        <div className="container relative space-y-8 md:my-12 lg:space-y-10 lg:my-14">
          {
            <div>
              <TabFilters
                filterType={tabNo === 1 ? "job" : "provider"}
                homeDistrict={homeDistrict}
                homeCity={homeCity}
                sethomeDistrict={sethomeDistrict}
                sethomeCity={sethomeCity}
                selectedPro={selectedPro}
                setselectedPro={setselectedPro}
                categories={categories}
                setSelectedcat={setselectedCat}
                selectedCat={selectedCat}
                allSubs={allSubs}
                setselectedSub={setselectedSub}
                selectedSub={selectedSub}
                allSizes={allSizes}
                selectedSize={selectedSize}
                setselectedSize={setselectedSize}
                allColours={allColours}
                selectedColour={selectedColour}
                setselectedColour={setselectedColour}
                setminPrice={setminPrice}
                minPrice={minPrice}
                setmaxPrice={setmaxPrice}
                maxPrice={maxPrice}
                filterSortValue={filterSortValue}
                setFilterSortValue={setFilterSortValue}
                setCurrentPage={setCurrentPage}
                setmainDesc={setmainDesc}
                setsearch={setsearch}
                search={search}
                selectedAge={selectedAge}
                setselectedAge={setselectedAge}
                selectedGender={selectedGender}
                setselectedGender={setselectedGender}
                selectedCast={selectedCast}
                setselectedCast={setselectedCast}
                selectedJob={selectedJob}
                setselectedJob={setselectedJob}
                selectedHorr={selectedHorr}
                setselectedHorr={setselectedHorr}
                selectedRace={selectedRace}
                setselectedRace={setselectedRace}
                selectedRel={selectedRel}
                setselectedRel={setselectedRel}
                selectedEdu={selectedEdu}
                setselectedEdu={setselectedEdu}
                selectedMarr={selectedMarr}
                setselectedMarr={setselectedMarr}
                setcountry={setcountry}
                country={country}
                selectedSubCat={selectedSubCat}
                setselectedSubCat={setselectedSubCat}
                tabNo={tabNo}
                height={height}
                setheight={setheight}
              />

              <div className="w-full flex items-center justify-center mt-3 md:mt-8">
                {tabNo === 1 ? (
                  <div className="grid w-full md:w-auto lg:grid-cols-2 gap-x-6">
                    <button
                      className={`ttnc-ButtonCircle rounded-full flex items-center justify-center !leading-none disabled:bg-opacity-70  
         w-full md:py-6 py-4 px-5 ${
           t("langKey") === "Si"
             ? "lg:w-[500px] h-[55px] sm:h-10"
             : "lg:w-[400px] h-[55px] sm:h-10"
         }  ${
                        selectedLan === "Si"
                          ? "bg-[#fcfcfc] border border-[#c61569] text-[#c61569]"
                          : "bg-[#fcfcfc] border border-[#c61569] text-[#c61569]"
                      }  font-medium mt-2 cursor-pointer `}
                      onClick={() => {
                        // getDetails();
                        setselectedLan("Si");
                      }}
                    >
                      {/* <i className="lab la-telegram-plane text-xl text-left mr-2"></i> */}
                      <span
                        className={`block  ${
                          t("langKey") === "Si" ? "text-sm" : "text-sm"
                        } font-medium`}
                      >
                        {t("hButton3")}
                      </span>
                    </button>

                    <button
                      className={`ttnc-ButtonCircle rounded-full flex items-center justify-center !leading-none disabled:bg-opacity-70  
         w-full md:py-6 py-5 px-10 ${
           t("langKey") === "Si"
             ? "lg:w-[500px] h-[60px] sm:h-11 "
             : "lg:w-[400px] h-[60px] sm:h-11"
         } ${
                        selectedLan === "En"
                          ? "border-image-gradient border-image-slice-1 bg-gradient-to-r from-[#031714] to-[#c61569] border text-slate-50"
                          : "bg-gradient-to-r from-[#031714] to-[#c61569] border text-slate-50"
                      }  font-medium mt-2 cursor-pointer `}
                      onClick={() => {
                        // getDetails();
                        setselectedLan("En");
                      }}
                    >
                      {/* <i className="lab la-telegram-plane text-xl text-left mr-2"></i> */}
                      <span
                        className={`block  ${
                          t("langKey") === "Si" ? "text-sm" : "text-sm"
                        } font-medium`}
                      >
                        {t("hButton4")}
                      </span>
                    </button>
                  </div>
                ) : (
                  <button
                    className={`ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-[#c61569] 
     w-full ${
       t("langKey") === "Si"
         ? "lg:w-[580px] h-14 sm:h-10 "
         : "lg:w-[400px] h-10 sm:h-10"
     }   text-slate-50 font-medium mt-2 cursor-pointer px-4`}
                    onClick={() => {
                      getDetails();
                    }}
                  >
                    {/* <i className="lab la-telegram-plane text-xl text-left mr-2"></i> */}
                    <span
                      className={`block  ${
                        t("langKey") === "Si"
                          ? "text-[11px] lg:text-sm"
                          : "text-sm"
                      } font-medium`}
                    >
                      {tabNo === 1 ? t("hButton") : t("hButton2")}
                    </span>
                  </button>
                )}
              </div>
            </div>
          }

          {tabNo === 1 ? (
            <SectionSliderJobs
              data={jobs}
              heading={"Ads"}
              subHeading="Submit your application for these jobs. Shortlisted candidates will be contacted by the employer directly"
              pageLimit={pageLimit}
              setpageLimit={setpageLimit}
            />
          ) : (
            <SectionSliderProviders
              data={providers}
              heading={"Service Providers"}
              subHeading="Find the best cleaning service providers near you FREE"
              pageLimit={pageLimit}
              setpageLimit={setpageLimit}
            />
          )}
        </div>
        {
          // reviews.length > 0 &&

          <div className=" py-10">
            <div className=" relative space-y-8 my-8 lg:space-y-16 lg:my-2">
              <SectionSliderSucessStories
                // data={reviews}
                heading={t("fSucs")}
                subHeading={t("fSucsSub")}
              />
            </div>
          </div>
        }
        {
          // reviews.length > 0 &&

          <div className="bg-gradient-to-r from-[#031714] to-[#c61569] py-0.5">
            <div className="container relative space-y-8 my-8 lg:space-y-16 lg:my-16">
              <SectionSliderReviews
                data={reviews}
                heading={t("fTesti")}
                subHeading={t("fTestiSub")}
                Reviews={Reviews}
              />
            </div>
          </div>
        }
      </div>

      <ModalQuickView
        show={openSuccess}
        onCloseModalQuickView={() => setopenSuccess(false)}
        msg="Your order has been placed successfully !"
      />

      <Modal
        open={false}
        // onClose={() => {
        //   setopenSuccess(false);
        // }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
              <CssBaseline />
              <Box
                sx={{
                  marginTop: 0,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                {/* <CheckCircleIcon
                  sx={{ m: 1, color: "#90EE90", width: 40, height: 40 }}
                /> */}
                <i
                  className="fa fa-check-circle text-3xl text-green-600 mt-1 "
                  aria-hidden="true"
                ></i>
                <Typography
                  className="text-green-600"
                  component="h1"
                  variant="h5"
                  style={{
                    fontSize: "14pt",
                    fontFamily: FONTS_INTER,
                    fontWeight: "bold",
                    marginTop: 7,
                    textAlign: "center"
                    // color: "#F1A700"
                  }}
                >
                  Your order has been placed successfully !
                </Typography>
                <Box component="form" sx={{ mt: 1 }}>
                  <Typography
                    component="h5"
                    variant="h5"
                    style={{
                      fontSize: "9pt",
                      fontFamily: FONTS_INTER,
                      marginTop: 7,

                      color: COLORS.Primary,
                      textAlign: "center"
                    }}
                  >
                    {/* Thank you for completing your weekly meal plan with us. Your
                    first order will be delivered on  between 3.30pm and 8.30pm every
                    day. Please check your order summary for more details. */}
                    {/* {`Thank you for completing your weekly meal plan with us. Your
                    first order will be delivered on ${localStorage.getItem('deliveryDate')} between 3.30pm and 8.30pm every
                    day. Please check your order summary for more details.`} */}

                    {/* {
                      getDeliverycharge() > 0 ?

                        `Thank you for completing your weekly meal plan with us. Your order will be delivered on ${getSuccessDates()} between 4pm and 8pm. Please make sure to be available someone to collect it from the delivery agent.`

                        :

                        "Thank you for completing your weekly meal plan with us. Your order can be collected on the following days between 4pm and 8pm."

                    } */}

                    {/* Thank you for completing your weekly meal plan with us. Your order can be collected or get delivered through Uber Package delivery on the following days between 4pm and  8pm. */}
                  </Typography>

                  {/* <Typography
                    component="h5"
                    variant="h5"
                    style={{
                      fontSize: "9pt",
                      fontFamily: FONTS_INTER,
                      marginTop: 7,

                      color: COLORS.Primary,
                      textAlign: "center",
                    }}
                  >
                    <p>
                      Breakfast 6am - 9pm <br />
                      Lunch 11 a.m - 1.30
                      <br />
                      Dinner 5.30 p.m - 8.30 p.m
                    </p>
                    <p>
                      27th Fri  -  28th,29th,30th & 31st Meals
                      <br />
                      31st Tue -  01st, 2nd & 3rd Meals
                    </p>
                    {getSuccessDates()}
                  </Typography> */}

                  {/* <Typography
                    component="body1"
                    variant="h5"
                    style={{
                      fontSize: "8pt",
                      fontFamily: FONTS_INTER,
                      marginTop: 7,

                      color: COLORS.Primary,
                      textAlign: "center",
                      // fontStyle: "italic",
                    }}
                  >
                    <p>
                     
                      Your order can be collected from our pickup location or get it delivered from
                      uber delivery centre.
                     

                    </p>
                  </Typography> */}

                  <Typography
                    // component="body1"
                    variant="h5"
                    style={{
                      fontSize: "8pt",
                      fontFamily: FONTS_INTER,
                      marginTop: 7,

                      color: COLORS.Primary,
                      textAlign: "center"
                      // fontStyle: "italic",
                    }}
                  >
                    <p>
                      {/* (Delivery times can be vary depending on the road traffic
                      conditions.) */}
                      {/* Please check your order confirmation email for more details. */}
                    </p>
                  </Typography>
                </Box>
              </Box>
            </Container>
          </ThemeProvider>
        </Box>
      </Modal>
    </>
  );
}

export default PageHome;
