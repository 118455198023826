import React, { FC, useEffect, useId, useState } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import CardCategory2 from "components/CardCategories/CardCategory2New";
import PopularCategory from "components/CardCategories/PopularCategory";
import SuccessCard from "components/CardCategories/SuccessCard";
import { Link } from "react-router-dom";
import ModalQuickView from "components/ModalReview";
import sec from "images/suc.webp";
import iandp from "images/Isharaa_Pramodya.png";
import jnr from "images/Jayomi_Ravi.png";
import kn from "images/Kalani_Nadun.png";
import iandp1 from "images/Isharaa_Pramodya.png";
import jnr1 from "images/Jayomi_Ravi.png";
import kn1 from "images/Kalani_Nadun.png";
import { GetSiteReviews, getWholeSalers } from "Services/API/Get";
import { t } from "i18next";

export interface SucessStoryDataData {
  name: string;
  desc: string;
  img: string;
}

export interface SucessStoryDataDataProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  subHeading?: string;
  data?: SucessStoryDataData[];
}

const SUCESSITEMS: SucessStoryDataData[] = [
  {
    name: "Nadun & Kalani",
    desc: "Royal Ramesses",
    img: kn
  },
  {
    name: "Isharaa & Pramodya",
    desc: "Centuria Hotel Embilipitiya",
    img: iandp
  },
  // {
  //   name: "Jayomi & Ravi",
  //   desc: "Cocoloco Gardens Piliyandala",
  //   img: jnr
  // },
  {
    name: "Nadun & Kalani",
    desc: "Royal Ramesses",
    img: kn1
  },
  {
    name: "Isharaa & Pramodya",
    desc: "Centuria Hotel Embilipitiya",
    img: iandp1
  },
  // {
  //   name: "Jayomi & Ravi",
  //   desc: "Cocoloco Gardens Piliyandala",
  //   img: jnr1
  // },
  {
    name: "Nadun & Kalani",
    desc: "Royal Ramesses",
    img: kn
  },
  {
    name: "Isharaa & Pramodya",
    desc: "Centuria Hotel Embilipitiya",
    img: iandp
  },
];

const SectionSliderSucessStories: FC<SucessStoryDataDataProps> = ({
  heading = "Shop by department",
  subHeading = "",
  className = "",
  itemClassName = "",
  data = SUCESSITEMS
}) => {
  const id = useId();
  const UNIQUE_CLASS = "glidejs" + id.replace(/:/g, "_");

  useEffect(() => {
 if (data.length > 0) {
    const OPTIONS: Glide.Options = {
      perView: 4, // 3 fully visible slides
      focusAt: "center",// Center the slides, showing partial slides on the sides
      gap: 30, // Adjust the gap to control how much of the side slides are visible
      autoplay: 3000,
      hoverpause: true,
      animationDuration: 800, 
      type: data.length > 5 ? "carousel" : "slider",
      animationTimingFunc: "linear", 
      breakpoints: {
        1280: {
          perView: 4,
          gap: 20,
          focusAt: "center", // Focus on the middle slide with partial slides on the sides
          type: data.length > 4 ? "carousel" : "slider"
        },
        1024: {
          gap: 20,
          perView: 3,
          focusAt: 1, // Same behavior for smaller screens
          type: data.length > 3 ? "carousel" : "slider"
        },
        768: {
          gap: 20,
          perView: 2,
          type: data.length > 2 ? "carousel" : "slider"
        },
        640: {
          gap: 20,
          perView: 1,
          type: data.length > 1 ? "carousel" : "slider"
        },
        500: {
          perView: 1,
          type: data.length > 1 ? "carousel" : "slider"
        }
      }
    };


      let slider = new Glide(`.${UNIQUE_CLASS}`, OPTIONS);
      slider.mount();
      // @ts-ignore
      return () => slider.destroy();
    }
  }, [UNIQUE_CLASS, data]);


  return (
    <>
      {
        // data.length > 0 &&

        <div
          className={`nc-SectionSliderCategories px-6 md:px-0 ${className} relative z-[1] `}
        >
          <div className={`${UNIQUE_CLASS} flow-root`}>
            <Heading
              desc={subHeading}
              hasNextPrev
              isCenter={true}
              className="mb-2 px-8 lg:mb-14 text-[#003d35]"
              className2={"text-[#002930]"}
              fontClass={`${
                t("langKey") === "Si" ? "text-2xl md:text-[40px]  font-[Abhaya]" : "text-xl md:text-[40px]"
              }  font-extrabold`}
            >
              {heading}
              {/* Revealing the Resounding Voices of <br /> Delight and Satisfaction */}
            </Heading>
            <div className="glide__track glide__track-ts " data-glide-el="track">
              <ul className="glide__slides glide__slides-v py-10">
                {data.map((item: any, index: any) => (
                  <li key={index} className={`glide__slide ${itemClassName}`}>
                    <SuccessCard
                      data={item}
                      featuredImage={item.img}
                      // name={item.name}
                      // desc={item.desc}
                      bgClass={item.color}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default SectionSliderSucessStories;
