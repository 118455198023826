import React, { FC, useState, useEffect, useRef, Fragment } from "react";
import Stripe from "images/sLogo.png"
import Label from "components/Label/Label";
import Select from "shared/Select/Select";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import { IMAGE_SERVER_URL, IMAGE_SERVER_URL_DEV, SERVER_URL, SITE_URL } from "Constant/System";
import Company1 from "images/avatars/Image-1.png";
import { addToast } from "shared/Toast";
import { GetApplicantBYJob, OrderbyID, getCleanQuote, getCleanQuoteProvider, getEvents, getOrderSummary, getProfiles } from "Services/API/Get";
import Prices from "components/Prices";
import moment from "moment";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import DatePicker from "react-multi-date-picker"
import { NavLink, useHistory } from "react-router-dom";
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { Autocomplete, Tooltip, Zoom } from "@mui/material";
import { SubmitQuote, UpdateOrderStatus, UpdateQuotationStatus } from "Services/API/Put";
import { EMAIL_TAMPLETE } from "containers/PageCheckout/emailQuote";
import { EMAIL_TAMPLETE2 } from "containers/PageCheckout/email3";
import { getCartList, getProductById2 } from "Services/API/Get";
import LoadingSpinner from "shared/LoadingSpinner";
import { DeleteProduct, DeleteRecieved } from "Services/API/Delete";
import { t } from "i18next";
import { Dialog, Popover, Transition } from "@headlessui/react";
import Checkbox from "shared/Checkbox/Checkbox";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import ModalQuickView from "components/ModalAd";


const renderXClear = () => {
    return (
        <span className="flex-shrink-0 w-4 h-4 rounded-full bg-primary-500 text-white flex items-center justify-center ml-3 cursor-pointer">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-3 w-3"
                viewBox="0 0 20 20"
                fill="currentColor"
            >
                <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                />
            </svg>
        </span>
    );
};

const STATUS = [
    "New order",
    "Quotation Submitted",
    "Paid",
    "Completed"
]


const OrderSummary = (props) => {

    const router = useHistory();

    const [images, setImages] = useState([]);

    const [showModalQuickView, setShowModalQuickView] = useState(false)

    const [events, setevents] = useState([]);

    const [search, setsearch] = useState("");
    const [category, setcategory] = useState("");

    const [selectedEvent, setselectedEvent] = useState("");

    const [fromDate, setfromDate] = useState("");
    const [toDate, settoDate] = useState("");

    const [currUser, setcurrUser] = useState(props.currUser);

    const componentRef = React.useRef();

    const [orderSummary, setordersummary] = useState([])
    const [order, setorder] = useState("")

    const [lopen, setlopen] = React.useState(false);

    const [profiles, setprofiles] = useState([]);
    const [selectedProfile, setselectedProfile] = useState("");
    const [adDetails, setadDetails] = useState("");

    const [sJob, setsJob] = useState("");



    const handlePrint = useReactToPrint({
        documentTitle: `Invoice`,
        content: () => componentRef.current,
        copyStyles: true,

    });

    const listProfiles = () => {


        getProfiles({
            jobType: JSON.stringify([]),
            // title: search,
            // jobType:JSON.stringify([])
        })
            .then((res) => {
                console.log(">>>>>>>profiles", res.data);

                const subArr = res.data.result?.map((d, key) => {
                    return {
                        ...d,
                        key: key,
                        // id: d.state_code
                    };
                });

                setprofiles(subArr);

                // setselectedProfile(subArr[0]);


                // if (selectedJob) {
                //     setselectedProfile(res.data.result.find((v) => v.id === selectedProfile?.id))
                // }
            })
            .catch((error) => {
                // setlopen(false);
                setprofiles([]);
                console.log("Error", error.response);
                // addToast(error.response.data.message, "warning");
                // setOpenToast(true);
                // setColor(COLORS.Orange);
                // setAlertClass("error");
                // setMsg(error.response.data.message);
            })
    };

    const repById = () => {
        GetApplicantBYJob({
            // jobId: selectedProfile?.id,
            memberId: selectedProfile ? selectedProfile?.id : null,
            title: search
        })
            .then((res) => {
                console.log(">>>>>>>replies", res.data);
                setevents(res.data.result);
            })
            .catch((error) => {
                // setlopen(false);
                setevents([]);
                console.log("Error", error.response);

                if (error.response.data.statusCode === 401) {
                    router.push("/login?selectPage=replies");
                    addToast("Please Login", "warning");
                }
                // addToast(error.response.data.message, "warning");
                // setOpenToast(true);
                // setColor(COLORS.Orange);
                // setAlertClass("error");
                // setMsg(error.response.data.message);
            })
    }

    useEffect(() => {
        repById();
    }, [selectedProfile])

    useEffect(() => {
        listProfiles();
    }, [])

    const getAllEvents = () => {
        console.log(">>>>>>sdsxcddf")
        getCleanQuoteProvider({
            search: search,
            fromDate: fromDate ? moment(fromDate).format("YYYY-MM-DD") : "1999-01-04",
            toDate: toDate ? moment(toDate).format("YYYY-MM-DD") : "9999-01-04",
            sellerId: (currUser.userType === "wholesaler" || currUser.userType === "retail supplier") ? currUser.id : "",
            serviceProviderId: currUser.id,
            userType: currUser.userType,
            isHire: 0
        })
            .then((res) => {
                console.log(">>>>>>>events", res.data);
                setevents(res.data.result);
            })
            .catch((error) => {
                // setlopen(false);
                setevents([]);
                console.log("Error", error.response);
                addToast(error.response.data.message, "warning");
                // setOpenToast(true);
                // setColor(COLORS.Orange);
                // setAlertClass("error");
                // setMsg(error.response.data.message);
            })
    }

    useEffect(() => {
        // getAllEvents();
    }, [showModalQuickView, search, fromDate, toDate])


    const renderProductItem = (product, index) => {
        const { image, name } = product;

        const thumblineImage = JSON.parse(
            product.image
        ).filter((item) => item.thumbnail === true)[0];






        return (
            <div key={index} className="flex py-4 sm:py-7 last:pb-0 first:pt-0">
                <div className="h-24 w-16 sm:w-20 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
                    <img
                        src={`${IMAGE_SERVER_URL_DEV}${thumblineImage?.path}`}
                        alt={JSON.parse(product.productDetails)[0]?.productName}
                        className="h-full w-full object-cover object-center"
                    />
                </div>

                <div className="ml-4 flex flex-1 flex-col">
                    <div>
                        <div className="flex justify-between ">
                            <div>
                                <h3 className="text-base font-medium line-clamp-1">{JSON.parse(product.productDetails)[0]?.productName}</h3>
                                <p className="mt-1 text-sm text-slate-500 dark:text-slate-400">
                                    <span className="capitalize">{JSON.parse(product.productDetails)[0].colour}</span>
                                    <span className="mx-2 border-l border-slate-200 dark:border-slate-700 h-4"></span>
                                    <span className="capitalize">{JSON.parse(product.productDetails)[0].size}</span>
                                </p>
                            </div>
                            <Prices price={product?.productPrice} className="mt-0.5 ml-2" />

                        </div>
                    </div>
                    <div className="flex flex-1 items-end justify-between text-sm">
                        <p className="text-gray-500 dark:text-slate-400 flex items-center">
                            <span className="hidden sm:inline-block">Qty</span>
                            <span className="inline-block sm:hidden">x</span>
                            <span className="ml-2"> {product.qty}</span>
                        </p>

                        {/* <div className="flex">
                  <button
                    type="button"
                    className="font-medium text-indigo-600 dark:text-primary-500 "
                  >
                    Leave review
                  </button>
                </div> */}
                    </div>
                </div>
            </div>
        );
    };

    const renderOrder = (cartItem, index) => {

        console.log(">><><><", cartItem)
        return (
            <div className="border border-slate-200 dark:border-slate-700 rounded-lg overflow-hidden z-0">
                <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center p-4 sm:p-8 bg-slate-50 dark:bg-slate-500/5">
                    <div>
                        <p className="text-base font-semibold">
                            {/* #WU3746HGG12 */}
                            {cartItem?.orderNo}
                        </p>
                        <p className="text-slate-500 dark:text-slate-400 text-sm mt-1.5 sm:mt-2">
                            <span>
                                {/* Aug 8, 2023 */}
                                {moment(cartItem.order[0].createdAt).format("MMM DD, yyyy")}
                            </span>
                            <span className="mx-2">·</span>
                            <span className="text-primary-500">{`$ ${cartItem.order[0].amountPayable.toFixed(2)}`}</span>
                        </p>
                    </div>
                    {/* <div className="mt-3 sm:mt-0">
                <ButtonSecondary
                  sizeClass="py-2.5 px-4 sm:px-6"
                  fontSize="text-sm font-medium"
                >
                 {`$ ${cartItem.order[0].amountPayable.toFixed(2)}`}
                </ButtonSecondary>
              </div> */}
                </div>
                <div className="border-t border-slate-200 dark:border-slate-700 p-2 sm:p-8 divide-y divide-y-slate-200 dark:divide-slate-700">
                    {cartItem.order.map(renderProductItem)}
                </div>
            </div>
        );
    };

    function CustomInput({ onFocus, value, onChange }) {
        return (
            <div className="mt-1.5 flex">
                <span className="inline-flex items-center px-2.5 rounded-l-lg border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                    <i className="text-xl las la-calendar"></i>
                </span>
                <Input
                    className="!rounded-l-none"
                    placeholder="DD-MM-YYYY"
                    onFocus={onFocus}
                    value={value}
                    onChange={onChange}

                />

            </div>
        )
    }

    const getOrder = (id) => {
        // const valueFromOrderSum = JSON.parse(localStorage.getItem("orderSummary"));
        // const id = valueFromOrderSum.orderNo

        OrderbyID({ ID: id })
            .then((res) => {

                console.log("order>>>>", res.data)

                const order = res.data[0]



                setordersummary(res.data)
                setorder(res.data[0])


            }).catch((err) => {
                console.log("######", err)


            })

    }

    useEffect(() => {
        if (order !== "") {
            handlePrint();
        }
    }, [order])


    const RenderQuoatation = ({ item }) => {

        console.log(">>>>ord", item)

        const [shipCost, setshipCost] = useState(item?.shippingCost ? item?.shippingCost : "");

        const [cartproducts, setcartproducts] = useState([]);

        const [order, setorder] = useState("")


        const getOrderDetails = () => {
            OrderbyID({ ID: item?.orderNo })
                .then((res) => {

                    console.log("order>>>>", res.data)

                    setcartproducts(res.data[0].productDetails);
                    setorder(res.data[0]);

                }).catch((err) => {
                    console.log("######", err)
                    setcartproducts([])
                    setorder("")
                })
        }

        const sendQuote = async () => {

            console.log(">>>>>>", parseFloat(shipCost))

            if (isNaN(parseFloat(shipCost))) {
                addToast("Please enter valid value", "warning");
                return;
            }

            const emailTem = await EMAIL_TAMPLETE({
                customerName: item?.firstName,
                invoiceNo: `${item?.orderNo}`.trim().toString(),
                pdfName: `${item?.firstName}_${`${item?.orderNo}`
                    .trim()
                    .toString()}`,
                url: `${SITE_URL}checkout?order=${btoa(item?.orderNo)}&buyer=${btoa(item?.userId)}`,
                logo: `${IMAGE_SERVER_URL_DEV + currUser?.avatar}`
            });

            const emailTem2 = await EMAIL_TAMPLETE2({
                customerName: `${item?.firstName} ${item?.lastName}`,
                address: item?.billingAddress,
                address2: `${item?.billingState}`,
                address3: `${item?.billingSuburb}`,
                address4: `${item?.billingpostCode}`,
                date: moment(new Date()).format("l"),
                invoiceNo: `${item?.orderNo}`.trim().toString(),
                tax: 0,
                amount: item?.itemTotal,
                delivery: shipCost,
                MESSAGE: "",
                iUsers: JSON.parse(cartproducts),
                currency: "$",
                logo: `${IMAGE_SERVER_URL_DEV + currUser?.avatar}`
            });

            console.log(">>>>", emailTem2)

            let obj = {
                emailBody: emailTem,
                pdfName: `${item?.firstName}_${`${item?.orderNo}_Quote`
                    .trim()
                    .toString()}`,
                emailTemplate: emailTem2,
                subject: "",
                shippingCost: parseFloat(shipCost).toFixed(2),
                amountPayable: parseFloat(parseFloat(shipCost) + item?.itemTotal).toFixed(2),
                email: order?.email
            }

            console.log(">>>>>", obj)

            setlopen(true);
            SubmitQuote(obj, item?.orderNo)
                .then((res) => {
                    setlopen(false);
                    console.log("res>>>>", res)
                    addToast("Quote successfully submitted", "success");
                    getAllEvents();

                }).catch((err) => {
                    console.log("######", err)
                    setlopen(false);



                })


        }

        useEffect(() => {
            // getCart();
            getOrderDetails();
        }, [])


        return (
            <>
                <Input widthClass="w-[324px] lg:w-full" className="text-neutral-700" placeholder="Quotation"
                    fontClass="text-sm"
                    disabled={(item?.orderStatus === "Paid" || item?.orderStatus === "Quotation Submitted")}
                    value={shipCost}
                    onChange={(e) => {
                        if (e.target.value) {
                            if (!isNaN(parseFloat(e.target.value)))
                                setshipCost(e.target.value);
                        } else {
                            setshipCost(e.target.value)
                        }

                    }}
                />
                {
                    (item?.orderStatus != "Paid" && item?.orderStatus != "Quotation Submitted") &&

                    <Tooltip title={"Submit Quote"} placement="right-start" TransitionComponent={Zoom} arrow >



                        <i className="lab la-telegram-plane text-xl text-left ml-1 text-[#2d867b] cursor-pointer"
                            onClick={sendQuote}
                        ></i>

                    </Tooltip>

                }

            </>


        );
    }


    return (
        <>
            <LoadingSpinner open={lopen} />

            <ModalQuickView
                show={showModalQuickView}
                onCloseModalQuickView={() => setShowModalQuickView(false)}
                jobId={adDetails}
                type={"job"}
                setselectedAdds={[]}
                form={true}
                reply={true}
            />

            <div className="justify-center w-full mt-12 ">

                <span className="block text-3xl font-bold flex justify-center w-full text-center">


                    {t("repHead")}

                </span>

                {/* <div className="flex justify-center">
                    <span className="text-xs font-normal flex justify-center w-full md:w-9/12 lg:w-[45%] text-center mt-1 mb-6">

                        You can view quotation inquiries that have been
                        made by service seekers on this page.

                    </span>
                </div> */}


            </div>

            <div className="w-full flex justify-center px-3 sm:mb-28 2xl:mb-28">

                <div className="w-full container">

                    <div className="mt-10 w-full sm:w-[300px]" >
                        {/* <Label className="text-sm">{t("adPro")}</Label> */}

                        <Popover className="relative">
                            {({ open, close }) => (
                                <>
                                    <Popover.Button
                                        className={`flex items-center justify-between px-2 w-full h-11 text-sm rounded-md border focus:outline-none select-none
              ${open ? "!border-primary-500 " : ""}
                ${selectedProfile ? "!border-primary-500 bg-primary-50 text-primary-900"
                                                : "bg-[#F4F4F4] border-[#F4F4F4] dark:border-neutral-700 text-neutral-700 dark:text-neutral-300 hover:border-neutral-400 dark:hover:border-neutral-500"
                                            }
                `}
                                    >


                                        <span className="ml-2 whitespace-nowrap text-gray-500 text-sm">{selectedProfile ? `${t("proHead")} ${selectedProfile?.key + 1}` : t("adPro")}
                                        </span>
                                        {!selectedProfile ? (
                                            <ChevronDownIcon className="w-4 h-4 ml-3" />
                                        ) : (
                                            <span onClick={() =>
                                                // setSizesState([])
                                                setselectedProfile("")
                                            }>{renderXClear()}</span>
                                        )}
                                    </Popover.Button>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-200"
                                        enterFrom="opacity-0 translate-y-1"
                                        enterTo="opacity-100 translate-y-0"
                                        leave="transition ease-in duration-150"
                                        leaveFrom="opacity-100 translate-y-0"
                                        leaveTo="opacity-0 translate-y-1"
                                    >
                                        <Popover.Panel className="absolute z-[999999] lg:w-screen lg:max-w-sm px-4 mt-3 left-0 sm:px-0 lg:max-w-sm">
                                            <div className="overflow-hidden rounded-2xl shadow-xl bg-white dark:bg-neutral-900 border border-neutral-200 dark:border-neutral-700">
                                                <div className="relative flex flex-col px-5 py-6 space-y-5">


                                                    {profiles.map((item, key) => (
                                                        <div key={item} className="">
                                                            <Checkbox
                                                                name={`${t("proHead")} ${key + 1}`}
                                                                label={`${t("proHead")} ${key + 1}`}
                                                                checked={selectedProfile?.key === key}
                                                                // defaultChecked={categoriesState.includes(item.mainId)}
                                                                sizeClassName="w-5 h-5"
                                                                labelClassName="text-sm font-normal"
                                                                onChange={(checked) => {

                                                                    setselectedProfile(item);

                                                                    close();

                                                                    // seterrjobType(false);

                                                                    // setadDetails(item);
                                                                    // var data = ""



                                                                    // setselectedGender([item]);

                                                                    // setSelectedcat(item.value);

                                                                    // setselectedColour("");
                                                                    // setselectedSize("");
                                                                    // setCurrentPage(1);
                                                                    // removeLocal();

                                                                }}
                                                            // onChange={(checked) => handleChangeCategories(checked, item.mainId)}
                                                            />

                                                        </div>
                                                    ))}

                                                </div>

                                            </div>
                                        </Popover.Panel>
                                    </Transition>
                                </>
                            )
                            }
                        </Popover >


                        {/* <Autocomplete
                            // readOnly={false}
                            className="mt-1.5"
                            // className="form-group"
                            // className="border-neutral-200"
                            disablePortal
                            // defaultValue={{ value: category }}
                            // value={{ name: profiles.find((v) => v.id === selectedProfile?.id) ? profiles.find((v) => v.id === selectedProfile?.id).name : "" }}
                            value={{ key: profiles.find((v) => v.id === selectedProfile?.id) ? (profiles.find((v) => v.id === selectedProfile?.id).key) : "" }}

                            // id="combo-box-demo"
                            options={profiles}
                            getOptionLabel={(option) => option ? (t("proHead") + " " + (option.key + 1)) : ""}
                            onChange={(e, value) => {
                                setselectedProfile(value);
                                // seterrjobType(false);

                                setadDetails(value);

                                // setCountry(value);
                            }}
                            // sx={{
                            //   // width: "80vw",
                            //   background:"transparent",
                            //   borderRadius: "20px",
                            //   // border: "none",
                            //   // borderColor: "transparent",

                            //   "& .MuiInputBase-input": {
                            //     height: 15,
                            //     // border: "none",
                            //     fontSize: "14px",
                            //     fontFamily: "'Jost', sans-serif",
                            //     // borderColor: "transparent",
                            //   },
                            //   //view
                            //   "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                            //     // borderColor: "transparent",
                            //   },

                            //   "&:hover .MuiOutlinedInput-input": {
                            //     color: "black",
                            //   },
                            //   "&:hover .MuiInputLabel-root": {
                            //     color: COLORS.GrayBackground,
                            //   },
                            //   "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                            //   {
                            //     // borderColor: "transparent",
                            //   },
                            //   ".MuiAutocomplete-root fieldset:hover": {
                            //     color: "transparent",
                            //     // borderColor: "transparent",
                            //   },
                            // }}
                            renderInput={(params) => (
                                <div ref={params.InputProps.ref}>
                                    <Input {...params.inputProps} className="" placeholder={t("adPro")} />
                                </div>
                            )}
                            ListboxProps={{
                                style: {
                                    maxHeight: "150px",
                                    fontSize: "14px",
                                    // border: '1px solid white'
                                },
                            }}
                        /> */}


                        {/* <Select className="mt-1.5"
                                onChange={(e) => {
                                    setjobType(e.target.value);
                                    seterrjobType(false);
                                }}
                                value={jobType}
                            >
                                <option value={""} className="" >Select a job type</option>
                                <option value="Seasonal">Seasonal</option>
                                <option value="Casual">Casual</option>
                                <option value="Temporary">Temporary</option>
                                <option value="Fixed">Fixed term contract</option>
                                <option value="Part Time">Permanent – Part Time</option>
                                <option value="Full Time">Permanent – Full time</option>

                            </Select> */}
                        {/* {errjobType && (
                    <div className="text-red-600 text-sm text-right mt-2">
                        This field is required
                    </div>
                )} */}
                    </div>

                    {/* <form className="mt-6 w-full">

                        <div class="relative">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                            </div>
                            <Input type="search" id="default-search" className="block w-full p-4 pl-10 text-base text-gray-900 border border-gray-300 rounded-lg  focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search Ref Number..."
                                onChange={(e) => {
                                    setsearch(e.target.value);
                                }}
                            />

                        </div>
                    </form>

                    <div className="w-full mt-4 space-y-4">

                        <div>
                            <Label className="text-sm">From</Label>
                            <div className="flex w-full">

                                <DatePicker

                                    format="DD/MM/YYYY"
                                    containerStyle={{
                                        width: "100%"
                                    }}
                                    render={<CustomInput />}

                                    onChange={(e, val) => {
                                        console.log(">>>>>>", e.toDate())
                                        setfromDate(e.toDate());

                                    }}
                                    value={fromDate}
                                // value={year}
                                // onChange={setyear}

                                />

                            </div>


                           
                            {false && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>
                        <div>
                            <Label className="text-sm">To</Label>
                            <div className="flex w-full">

                                <DatePicker

                                    format="DD/MM/YYYY"
                                    containerStyle={{
                                        width: "100%"
                                    }}
                                    render={<CustomInput />}

                                    onChange={(e, val) => {
                                        console.log(">>>>>>", e.toDate())
                                        settoDate(e.toDate());

                                    }}
                                    value={toDate}
                                // value={year}
                                // onChange={setyear}

                                />

                            </div>

                          
                            {false && (
                                <div className="text-red-600 text-sm text-right mt-2">
                                    This field is required
                                </div>
                            )}
                        </div>


                    </div> */}
                    <div className="w-full">
                        <div className="mt-10 space-y-0 overflow-x-auto">

                            {


                                <>

                                    {
                                        false &&
                                        <>
                                            <div className="flex ">
                                                <div className="bg-gray-100 flex w-full px-4 py-3 rounded-lg space-x-4 ">



                                                    <div className="w-5/12 flex justify-start items-center">
                                                        <div>
                                                            <div className="text-base font-bold flex justify-start items-center">Date</div>


                                                            {/* <div className="text-sm font-medium flex justify-start items-center">{moment(item?.createdAt).format("YYYY-MM-DD HH:MM:SS")}</div> */}
                                                        </div>
                                                    </div>
                                                    <div className="w-5/12 flex justify-start items-center">
                                                        <div>
                                                            <div className="text-base font-bold flex justify-start items-center">Customer</div>


                                                            {/* <div className="text-sm font-medium flex justify-start items-center">{moment(item?.createdAt).format("YYYY-MM-DD HH:MM:SS")}</div> */}
                                                        </div>
                                                    </div>
                                                    <div className="w-7/12 flex justify-start items-center">
                                                        <div>
                                                            <div className="text-base font-bold flex justify-start items-center">Email</div>


                                                            {/* <div className="text-sm font-medium flex justify-start items-center">{moment(item?.createdAt).format("YYYY-MM-DD HH:MM:SS")}</div> */}
                                                        </div>
                                                    </div>
                                                    <div className="w-5/12 flex justify-start items-center">
                                                        <div>
                                                            <div className="text-base font-bold flex justify-start items-center">Phone</div>


                                                            {/* <div className="text-sm font-medium flex justify-start items-center">{moment(item?.createdAt).format("YYYY-MM-DD HH:MM:SS")}</div> */}
                                                        </div>
                                                    </div>

                                                    {/* <div className="w-6/12 flex justify-start items-center">
                                                <div>
                                                    <div className="text-base font-bold flex justify-start items-center">Cleaning Types</div>

                                                    <div className="text-sm font-medium flex justify-start items-center">{item?.orderNo}</div>
                                                </div>
                                            </div> */}

                                                    <div className="w-6/12 flex justify-start items-center">
                                                        <div>
                                                            <div className="text-base font-bold flex justify-start items-center">Description</div>

                                                            {/* <div className="text-sm font-medium flex justify-start items-center">{item?.orderNo}</div> */}
                                                        </div>
                                                    </div>

                                                    <div className="w-5/12 flex justify-start items-center">
                                                        <div>
                                                            <div className="text-base font-bold flex justify-start items-center">Status</div>

                                                            {/* <div className="text-sm font-medium flex justify-start items-center">{item?.orderNo}</div> */}
                                                        </div>
                                                    </div>

                                                    {/* <div className="w-full flex justify-start items-center">
                                                <div>
                                                    <div className="text-base font-bold flex justify-start items-center">Customer</div>


                                                </div>
                                            </div> */}

                                                    {/* <div className="w-4/12 flex justify-start items-center">
                                                <div>
                                                    <div className="text-base font-bold flex justify-start items-center">Amount</div>

                                                </div>
                                            </div>
                                            <div className="w-11/12 flex justify-start items-center">
                                                <div>
                                                    <div className="text-base font-bold flex justify-start items-center">Status</div>

                                                </div>


                                            </div> */}


                                                </div>





                                            </div>

                                            <div className="flex items-center">

                                                <i className="las la-trash-alt text-xl text-left ml-2 text-transparent"
                                                    onClick={() => {
                                                        // DeleteRecieved(item?.id)
                                                        //     .then((res) => {

                                                        //         addToast("Quotation successfully deleted", "success");
                                                        //         getAllEvents();
                                                        //     })
                                                        //     .catch((error) => {
                                                        //         // setlopen(false);
                                                        //         console.log("Error", error.response);
                                                        //         addToast(error.response.data.message, "warning");
                                                        //         // setOpenToast(true);
                                                        //         // setColor(COLORS.Orange);
                                                        //         // setAlertClass("error");
                                                        //         // setMsg(error.response.data.message);
                                                        //     })
                                                    }}
                                                ></i>
                                            </div>
                                        </>

                                    }



                                    {
                                        [1].map((item, index) => {
                                            return (
                                                <>
                                                    <div className="flex ">
                                                        <div className={`w-full  bg-gray-100 text-[#2d867b] space-y-2 overflow-x-auto xl:overflow-hidden ${((events.length - 1) === index) ? "rounded-b-lg" : ""} ${(index === 0) ? "rounded-t-lg" : ""}`}>

                                                            {
                                                                index === 0 &&

                                                                <>

                                                                    <div className=" flex px-4 py-3 space-x-4  xl:bg-gradient-to-r from-[#031714] to-[#c61569] xl:text-white ">
                                                                        <div className="sm:w-2/12 flex justify-start items-center">
                                                                            <div>


                                                                                <div className="w-[60px] text-sm font-medium flex justify-start items-center">{t("fType")}</div>


                                                                            </div>
                                                                        </div>
                                                                        <div className="w-1/12 flex justify-start items-center">
                                                                            <div>


                                                                                <div className="w-[40px]  text-sm font-medium flex justify-start items-center">{t("fAge")}</div>


                                                                            </div>
                                                                        </div>
                                                                        <div className="w-1/12 flex justify-start items-center">
                                                                            <div>



                                                                                <div className="w-[30px] text-sm font-medium flex justify-start items-center">{t("fHei")}
                                                                                </div>


                                                                            </div>
                                                                        </div>
                                                                        <div className="w-4/12 flex justify-start items-center">
                                                                            <div>



                                                                                <div className="w-[120px] text-sm font-medium flex justify-start items-center">{t("fEdu")}
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                        <div className="w-7/12 flex justify-start items-center">
                                                                            <div>



                                                                                <div className="w-[220px] text-sm font-medium flex justify-start items-center">{t("fOcc")}
                                                                                </div>

                                                                            </div>
                                                                        </div>

                                                                        <div className="w-3/12 flex justify-start items-center">
                                                                            <div>



                                                                                <div className="w-[70px] text-sm font-medium flex justify-start items-center">{t("fCast")}
                                                                                </div>

                                                                            </div>
                                                                        </div>

                                                                        <div className="w-5/12 flex justify-start items-center">
                                                                            <div>



                                                                                <div className="w-[80px] text-sm font-medium flex justify-start items-center">{t("fHor")}
                                                                                </div>

                                                                            </div>
                                                                        </div>

                                                                        <div className="w-5/12 flex justify-start items-center">
                                                                            <div>



                                                                                <div className="w-[40px] text-sm font-medium flex justify-start items-center">{t("fRel")}
                                                                                </div>

                                                                            </div>
                                                                        </div>

                                                                        <div className="w-5/12 flex justify-start items-center">
                                                                            <div>



                                                                                <div className="w-[120px] text-sm font-medium flex justify-start items-center">{t("fMarr")}
                                                                                </div>

                                                                            </div>
                                                                        </div>

                                                                        <div className="w-5/12 flex justify-start items-center">
                                                                            <div>



                                                                                <div className="w-[80px] text-sm font-medium flex justify-start items-center">{t("repCountry")}</div>

                                                                            </div>
                                                                        </div>
                                                                        <div className="w-6/12 flex justify-start items-center cursor-pointer">
                                                                            <div>



                                                                                <div className="invisible w-full text-xs font-medium flex justify-center items-center text-white bg-[#B69D52] px-5 py-1 rounded-[20px]"
                                                                                    onClick={() => {
                                                                                        setadDetails(item);
                                                                                        setShowModalQuickView(true)
                                                                                    }}

                                                                                >View more</div>

                                                                            </div>
                                                                        </div>





                                                                    </div>




                                                                </>


                                                            }
                                                            {/* <div className="flex justify-start w-3/12 items-center">

                                                            {
                                                                [1,].map((data, index) => {
                                                                    return (
                                                                        <>
                                                                            <div className="w-12 h-12 object-fill bg-contain rounded-full border-2 border-white"
                                                                                    style={{
                                                                                        backgroundImage: `url(${IMAGE_SERVER_URL_DEV}${item?.avatar})`
                                                                                    }}
                                                                                >

                                                                                </div>
                                                                        </>)
                                                                })
                                                            }


                                                        </div> */}

                                                            {
                                                                events.length > 0 && events.map((item, index) => {
                                                                    return (
                                                                        <>
                                                                            <div className=" flex px-4 py-3 space-x-4 bg-gray-100 text-[#2d867b] ">
                                                                                <div className="sm:w-2/12 flex justify-start items-center">
                                                                                    <div>


                                                                                        <div className="w-[60px] text-sm font-medium flex justify-start items-center">{item.gender}</div>


                                                                                    </div>
                                                                                </div>
                                                                                <div className="w-1/12 flex justify-start items-center">
                                                                                    <div>


                                                                                        <div className="w-[40px]  text-sm font-medium flex justify-start items-center">{item?.age}</div>


                                                                                    </div>
                                                                                </div>
                                                                                <div className="w-1/12 flex justify-start items-center">
                                                                                    <div>



                                                                                        <div className="w-[30px] text-sm font-medium flex justify-start items-center">{item?.height}</div>


                                                                                    </div>
                                                                                </div>
                                                                                <div className="w-4/12 flex justify-start items-center">
                                                                                    <div>



                                                                                        <div className="w-[120px] text-sm font-medium flex justify-start items-center">{item?.educationStatus}</div>

                                                                                    </div>
                                                                                </div>
                                                                                <div className="w-7/12 flex justify-start items-center">
                                                                                    <div>



                                                                                        <div className="w-[220px] text-sm font-medium flex justify-start items-center">{item?.occupation}</div>

                                                                                    </div>
                                                                                </div>

                                                                                <div className="w-3/12 flex justify-start items-center">
                                                                                    <div>



                                                                                        <div className="w-[70px] text-sm font-medium flex justify-start items-center">{item?.cast}</div>

                                                                                    </div>
                                                                                </div>

                                                                                <div className="w-5/12 flex justify-start items-center">
                                                                                    <div>



                                                                                        <div className="w-[80px] text-sm font-medium flex justify-start items-center">{item?.horoscopStatus}</div>

                                                                                    </div>
                                                                                </div>

                                                                                <div className="w-5/12 flex justify-start items-center">
                                                                                    <div>



                                                                                        <div className="w-[40px] text-sm font-medium flex justify-start items-center">{item?.religion}</div>

                                                                                    </div>
                                                                                </div>

                                                                                <div className="w-5/12 flex justify-start items-center">
                                                                                    <div>



                                                                                        <div className="w-[120px] text-sm font-medium flex justify-start items-center">{item?.marriageStatus}</div>

                                                                                    </div>
                                                                                </div>

                                                                                <div className="w-5/12 flex justify-start items-center">
                                                                                    <div>



                                                                                        <div className="w-[80px] text-sm font-medium flex justify-start items-center">{item?.currentResidesCountry}</div>

                                                                                    </div>
                                                                                </div>
                                                                                <div className="w-6/12 flex justify-start items-center cursor-pointer pr-4">
                                                                                    <div>



                                                                                        <div className="w-full text-xs font-medium flex justify-center items-center text-white bg-[#B69D52] px-5 py-1 rounded-[20px] whitespace-nowrap"
                                                                                            onClick={() => {
                                                                                                setadDetails(item);
                                                                                                setShowModalQuickView(true)
                                                                                            }}

                                                                                        >View more</div>

                                                                                    </div>
                                                                                </div>

                                                                                {/* <div className="w-6/12 flex justify-start items-center">
                                                                <div>

                                                                    <div className="text-sm font-medium flex justify-start items-center">{item?.cleaningTypes}</div>

                                                                </div>
                                                            </div> */}

                                                                                {/* <div className="w-6/12 flex justify-start items-center">
                                                                                    <div>


                                                                                        <div className="min-w-[100px] sm:w-full text-sm font-normal flex justify-start items-center">{item?.description}</div>
                                                                                    </div>
                                                                                </div>

                                                                                <div className="w-5/12 flex justify-start items-center">
                                                                                    <div className="flex">



                                                                                        <label class="relative inline-flex items-center cursor-pointer mt-3">
                                                                                            <input type="checkbox" class="sr-only peer"
                                                                                                onChange={(e) => {
                                                                                                    console.log(e.target.checked)
                                                                                                    // setstatus(e.target.checked)

                                                                                                    let obj = {
                                                                                                        IsQuotatiionSent: e.target.checked ? 1 : 0
                                                                                                    }

                                                                                                    UpdateQuotationStatus(obj, item?.id)
                                                                                                        .then((res) => {
                                                                                                            getAllEvents();
                                                                                                        })
                                                                                                        .catch((error) => {


                                                                                                            addToast(error.response.data.message, "warning");
                                                                                                            // setOpenToast(true);
                                                                                                            // setColor(COLORS.Orange);
                                                                                                            // setAlertClass("error");
                                                                                                            // setMsg(error.response.data.message);
                                                                                                        })


                                                                                                }}
                                                                                                checked={item?.IsQuotatiionSent === 1}
                                                                                                id="product-status-active"

                                                                                            />
                                                                                            <div class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-[#2d867b]"></div>
                                                                                            <label class="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300 cursor-pointer" htmlFor="product-status-active">Complete</label>

                                                                                        </label>


                                                                                        <div className="flex items-center mt-2 ml-3">

                                                                                            <i className="las la-trash-alt text-xl text-left ml-2 text-red-500 cursor-pointer"
                                                                                                onClick={() => {
                                                                                                    DeleteRecieved(item?.id)
                                                                                                        .then((res) => {

                                                                                                            addToast("Quotation successfully deleted", "success");
                                                                                                            getAllEvents();
                                                                                                        })
                                                                                                        .catch((error) => {
                                                                                                            // setlopen(false);
                                                                                                            console.log("Error", error.response);
                                                                                                            addToast(error.response.data.message, "warning");
                                                                                                            // setOpenToast(true);
                                                                                                            // setColor(COLORS.Orange);
                                                                                                            // setAlertClass("error");
                                                                                                            // setMsg(error.response.data.message);
                                                                                                        })
                                                                                                }}
                                                                                            ></i>
                                                                                        </div>


                                                                                    </div>
                                                                                </div> */}



                                                                            </div>
                                                                        </>
                                                                    );
                                                                })
                                                            }









                                                        </div>
                                                    </div>
                                                </>
                                            );
                                        })
                                    }
                                </>


                            }


                            {events.length === 0 &&


                                <>
                                    <div className="flex !mt-20 justify-center items-center">
                                        <p className="text-xl font-semibold">
                                            {/* #WU3746HGG12 */}
                                            No Replies Found
                                        </p>

                                    </div>
                                    {/* <div className="flex  justify-center items-center">

                                        <NavLink to={"/service-providers"}>
                                            <ButtonPrimary>Shop Now</ButtonPrimary>
                                        </NavLink>
                                    </div> */}

                                </>

                            }

                        </div>

                    </div>


                </div>
            </div>

            <div class="container" style={{ display: "none" }}>
                <div className="row " ref={componentRef}  >
                    <div className="intro-y box overflow-hidden mt-5 px-7">
                        <div className="flex flex-col lg:flex-row pt-4 lg:pb-20 text-center sm:text-left">
                            <div className="flex flex-col -mt-2">
                                <img
                                    src={IMAGE_SERVER_URL_DEV + currUser?.avatar}
                                    className="w-[100px]" ></img>

                                <div className="font-semibold text-primary text-3xl mt-4">INVOICE</div>

                            </div>

                            <div className="mt-20 lg:mt-0 lg:ml-auto lg:text-right w-full">
                                {/* <div className="flex justify-end -mr-4">
              <img src={logoUrl} className="w-[350px]" ></img>

            </div>

            <div className="font-semibold text-primary text-3xl">INVOICE</div> */}

                                <div className="text-xl text-primary font-medium">{currUser?.businessName}</div>

                                <div className="mt-1">{`${currUser?.homeAddress},`}</div>
                                <div className="mt-1">{`${currUser?.homeCity},`}</div>
                                <div className="mt-1">{`${currUser?.homeDistrict} ,`}</div>
                                <div className="mt-1">{`${currUser?.homepostCode}`}</div>
                                <div className="mt-1">{currUser?.contactNumber}</div>
                                <div className="mt-1">{currUser?.email}</div>
                            </div>
                        </div>
                        <div className="flex flex-col lg:flex-row border-b pt-0 pb-0 text-center sm:text-left">
                            {order.firstName !== "undefined" && order.lastName !== "undefined" &&

                                <div>
                                    <div className="text-base text-slate-500">Client Details</div>
                                    <div className="text-lg font-medium text-primary mt-2">
                                        {order.firstName + " " + order.lastName}
                                    </div>
                                    <div className="min-h-[200px]">
                                        <div className="mt-1">{order.email}</div>
                                        <div className="mt-1">{order.phoneNumber}</div>
                                        <div className="mt-1">{order.billingAddress}</div>
                                        <div className="mt-1">{order.billingSuburb}</div>
                                        <div className="mt-1">{order.billingState}</div>
                                        <div className="mt-1">{order.billingpostCode}</div>
                                    </div>
                                </div>


                            }

                            <div className="mt-10 lg:-mt-2 lg:ml-auto lg:text-right">
                                <div className="text-base text-slate-500">Receipt</div>
                                <div className="text-lg text-primary font-medium mt-2">
                                    {order.orderNo}
                                </div>
                                <div className="mt-1">{order?.createdAt?.split("T")[0]}</div>
                            </div>
                        </div>
                        <div className="w-full pb-6">
                            <div className="overflow-x-auto w-full">
                                <table className="table w-full">
                                    <thead >
                                        <tr className="">
                                            <th className="border-b-2 dark:border-darkmode-400 whitespace-nowrap py-4">
                                                Product
                                            </th>
                                            <th className="border-b-2 dark:border-darkmode-400 text-right whitespace-nowrap">
                                                QTY
                                            </th>
                                            <th className="border-b-2 dark:border-darkmode-400 text-right whitespace-nowrap">
                                                PRICE
                                            </th>
                                            <th className="border-b-2 dark:border-darkmode-400 text-right whitespace-nowrap">
                                                SUBTOTAL
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orderSummary.length > 0 && orderSummary.map((faker, fakerKey) => {

                                            let product = JSON.parse(faker?.productDetails)[fakerKey]

                                            if (product?.unitPrice) {
                                                product.unitprice = product.unitPrice
                                            }

                                            return (

                                                <tr>
                                                    <td className="border-b dark:border-darkmode-400">
                                                        <div className="font-medium whitespace-nowrap">
                                                            {faker.productName}
                                                        </div>
                                                        {
                                                            product.sku &&

                                                            <p className="text-slate-500 text-sm mt-0.5 whitespace-nowrap">
                                                                SKU: {product.sku}
                                                            </p>
                                                        }
                                                        {product.size &&

                                                            <div className="text-slate-500 text-sm mt-0.5 whitespace-nowrap">
                                                                Size: {product.size}
                                                            </div>

                                                        }

                                                        {product.colour &&

                                                            <div className="text-slate-500 text-sm mt-0.5 whitespace-nowrap">
                                                                Color: {product.colour}
                                                            </div>

                                                        }

                                                    </td>
                                                    <td className="text-right border-b dark:border-darkmode-400 w-32">
                                                        {faker.qty}
                                                    </td>
                                                    <td className="text-right border-b dark:border-darkmode-400 w-32">
                                                        $ {parseFloat(product.unitprice).toFixed(2)}
                                                    </td>
                                                    <td className="text-right border-b dark:border-darkmode-400 w-32 font-medium">
                                                        $ {parseFloat(faker.returned === 1 ? (product.unitprice * faker.qty * -1) : (product.unitprice * faker.qty)).toFixed(2)}
                                                    </td>
                                                </tr>

                                            );

                                        })}


                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="pb-10 sm:pb-20 flex flex-col-reverse sm:flex-row">
                            <div className="text-center sm:text-left mt-10 sm:mt-0">
                                {/* <div className="text-base text-slate-500">Bank Transfer</div>
            <div className="text-lg text-primary font-medium mt-2">
              Elon Musk
            </div>
            <div className="mt-1">Bank Account : 098347234832</div>
            <div className="mt-1">Code : LFT133243</div> */}
                            </div>
                            <div className="text-center sm:text-right sm:ml-auto">
                                <div className="mt-1">Sub Total &ensp;  $ {parseFloat((order.itemTotal)).toFixed(2)}</div><br />
                                {
                                    order?.payment !== "pos" && order?.shippingCost > 0 &&

                                    <>
                                        <div className="mt-1">Shipping &ensp;  $ {parseFloat(order?.shippingCost).toFixed(2)}</div><br />
                                    </>
                                }
                                {
                                    // order.orderNo === "10001" &&

                                    <>
                                        {/* <div className="mt-1">Tax (10% GST) &ensp;  $ {parseFloat((order?.payment !== "pos" ? (order.itemTotal + order.shippingCost) : order.itemTotal) * 10 / 100).toFixed(2)}</div><br /> */}
                                    </>




                                }
                                {/* <div className="mt-1">Tax (10% GST) &ensp;  $ {parseFloat((order?.payment !== "pos" ? order.itemTotal : order.itemTotal) * 10 / 100).toFixed(2)}</div><br /> */}
                                <div className="text-base text-slate-500">Total Amount</div>
                                <div className="text-xl text-primary font-medium mt-2">
                                    $ {parseFloat(order.amountPayable).toFixed(2)}
                                </div>
                                {/* <div className="mt-1">Taxes included</div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )

}

export default OrderSummary;