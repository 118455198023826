import React, { FC, useEffect, useId, useState } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import CardCategory2 from "components/CardCategories/CardCategory2New";
import PopularCategory from "components/CardCategories/PopularCategory";
import ReviewCard from "components/CardCategories/ReviewCardNew";
import department1Png from "images/brands/logo-1.png";
import department2Png from "images/brands/logo-2.png";
import department3Png from "images/brands/logo-3.png";
import department4Png from "images/brands/logo-4.png";
import department5Png from "images/brands/logo-5.png";
import department6Png from "images/brands/logo-6.png";
import department7Png from "images/brands/logo-7.png";
import { Link } from "react-router-dom";
import ModalQuickView from "components/ModalReview";
import { CurrentUser, getJobs, getSellerReviews } from "Services/API/Get";
import { addToast } from "shared/Toast";

export interface CardCategoryData {
  name: string;
  desc: string;
  img: string;
  color?: string;
}
const CATS: CardCategoryData[] = [
  {
    name: "Travel Kits",
    desc: "20+ categories",
    img: department1Png,
    color: "bg-indigo-100",
  },
  {
    name: "Beauty Products",
    desc: "10+ categories",
    img: department2Png,
    color: "bg-slate-100",
  },
  {
    name: "Sport Kits",
    desc: "34+ categories",
    img: department3Png,
    color: "bg-sky-100",
  },
  {
    name: "Pets Food",
    desc: "12+ categories",
    img: department4Png,
    color: "bg-orange-100",
  },
  {
    name: "Pets Food",
    desc: "12+ categories",
    img: department5Png,
    color: "bg-orange-100",
  },
  {
    name: "Pets Food",
    desc: "12+ categories",
    img: department6Png,
    color: "bg-orange-100",
  },
  {
    name: "Pets Food",
    desc: "12+ categories",
    img: department7Png,
    color: "bg-orange-100",
  },
];
export interface SectionSliderCategoriesProps {
  className?: string;
  itemClassName?: string;
  heading?: string;
  subHeading?: string;
  data?: CardCategoryData[];
  proId?: any;
  setreviewData?: any;
  setreviewRank?: any
}

const SectionSliderCategories: FC<SectionSliderCategoriesProps> = ({
  heading = "",
  subHeading = "",
  className = "",
  itemClassName = "",
  data = CATS,
  proId,
  setreviewData,
  setreviewRank
}) => {
  const id = useId();
  const UNIQUE_CLASS = "glidejs" + id.replace(/:/g, "_");

  const [showModalQuickView, setShowModalQuickView] = useState(false);

  const [reviews, setreviews] = useState([]);


  const getAllReviews = () => {
    getSellerReviews(proId ? proId : null)
      .then((res) => {
        console.log(">>>>>>>reviews", res.data);
        setreviews(res.data);
        setreviewData(res.data);
      })
      .catch((error) => {
        // setlopen(false);
        setreviews([]);
        setreviewData([]);
        console.log("Error", error.response);
        // addToast(error.response.data.message, "warning");
        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.data.message);
      })
  }

  useEffect(() => {
    getAllReviews();
  }, [showModalQuickView])

  const getCurrentUser = () => {
    CurrentUser(proId ? proId : null)
      .then((res) => {
        if (res.statusCode === 200) {
          const cData = res.data[0];
          // setUser(cData);
          setreviewRank(cData?.rank)
          const obj = {
            id: cData.id,
            email: cData?.email,
            businessName: cData?.businessName,
            contactName: cData?.contactName,
            phone: cData?.mobileNumber,
          };
          //   this.setState({
          //     businessName: cData?.businessName,
          //     contactName: cData?.contactName,
          //     email: cData?.email,
          //     tele: cData?.phone,
          //   });
          console.log("<><><><>", cData);
          // setcurrUser(cData);
          // setName(cData?.businessName + " " + cData?.contactName);
          // setfirstName(cData?.firstName);
          // setlastName(cData?.lastName);
          // setcategory(cData?.supplierCategory !== null ? cData?.supplierCategory : "");
          // setbusinessName(cData?.businessName !== null ? cData?.businessName : "");
          // setbusinessReg(cData?.businessRegistrationNumber !== null ? cData?.businessRegistrationNumber : "");
          // setyear(cData?.establishedYear !== null ? cData?.establishedYear : "");
          // setwebsite(cData?.website !== null ? cData?.website : "");
          // setcountry(cData?.country !== null ? cData?.country : "");
          // setmobile(cData?.contactNumber !== null ? cData?.contactNumber : "");
          // setmobile2(cData?.contactNumber2 !== null ? cData?.contactNumber2 : "");
          // setcontactName(cData?.contactName !== null ? cData?.contactName : "");
          // setemail(cData?.email);
          // setpremail(cData?.email);
          // setabout(cData?.about !== null ? cData?.about : "");
          // sethomeDistrict(cData?.homeDistrict !== null ? cData?.homeDistrict : "");
          // sethomeAddress(cData?.homeAddress !== null ? cData?.homeAddress : "");
          // sethomeCity(cData?.homeCity !== null ? cData?.homeCity : "");
          // sethomeNo(cData?.homeNumber !== null ? cData?.homeNumber : "");
          // sethomepostCode(cData?.homepostCode !== null ? cData?.homepostCode : "");
          // setuserId(cData?.id);


          // localStorage.setItem(
          //   `${window.location.hostname}.profile`,
          //   JSON.stringify(obj)
          // );
        }
      })
      .catch((error) => {
        console.log("error", error);
        console.log("current user error", error.response.data.message);
      });
  };

  React.useEffect(() => {
    getCurrentUser();

  }, [showModalQuickView]);

  useEffect(() => {

    if (reviews.length > 0) {

      const OPTIONS: Glide.Options = {
        perView: 5,
        gap: 10,
        autoplay: 3000,
        hoverpause: false,
        type: reviews.length > 5 ? 'carousel' : 'slider',
        bound: true,
        breakpoints: {
          1280: {
            perView: 4,
            type: reviews.length > 4 ? 'carousel' : 'slider',
          },
          1024: {
            gap: 20,
            perView: 3,
            type: reviews.length > 3 ? 'carousel' : 'slider',
          },
          768: {
            gap: 20,
            perView: 2,
            type: reviews.length > 2 ? 'carousel' : 'slider',
          },
          640: {
            gap: 20,
            perView: 1,
            type: reviews.length > 1 ? 'carousel' : 'slider',
          },
          500: {
            // gap: 20,
            perView: 1,
            type: reviews.length > 1 ? 'carousel' : 'slider',
          },
        },
      };

      let slider = new Glide(`.${UNIQUE_CLASS}`, OPTIONS);
      slider.mount();
      // @ts-ignore
      return () => slider.destroy();

    }





  }, [UNIQUE_CLASS, reviews]);



  return (
    <>
      {
        reviews?.length > 0 ?
          <div className={`nc-SectionSliderCategories ${className} relative z-[1] `}>
            <div className={`${UNIQUE_CLASS} flow-root`}>
              {/* <Heading desc={subHeading} hasNextPrev isCenter={true} className="mb-12 lg:mb-14 text-slate-100" className2={"text-slate-100"}>
          {heading}
        </Heading> */}
              <div className="glide__track mt-6" data-glide-el="track">
                <ul className="glide__slides">
                  {reviews?.length > 0 && reviews?.map((item: any, index: any) => (
                    <li key={index} className={`glide__slide ${itemClassName}`}>
                      <ReviewCard
                        item={item}
                        featuredImage={item.img}
                        // name={item.name}
                        // desc={item.desc}
                        bgClass={item.color}
                      />
                    </li>
                  ))}
                  {/* <li className={`glide__slide ${itemClassName}`}>
            <div
              className={`flex-1 relative w-full h-0 rounded-2xl overflow-hidden group aspect-w-1 aspect-h-1 bg-slate-100`}
            >
              <div>
                <div className="absolute inset-y-6 inset-x-10 flex flex-col sm:items-center justify-center">
                  <div className="flex relative text-slate-900">
                    <span className="text-base font-semibold ">
                      More collections
                    </span>
                    <svg
                      className="absolute left-full w-5 h-5 ml-2 rotate-45 group-hover:scale-110 transition-transform"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.0701 9.57L12.0001 3.5L5.93005 9.57"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M12 20.4999V3.66992"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </div>
                  <span className="text-sm mt-1 text-slate-800">
                    Show me more
                  </span>
                </div>
              </div>
              <Link
                to={"/"}
                className="opacity-0 group-hover:opacity-100 absolute inset-0 bg-black bg-opacity-10 transition-opacity"
              ></Link>
            </div>
          </li> */}
                </ul>
              </div>
              <div className="flex w-full mt-4 items-center justify-center">
                <button
                  className={
                    `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-gradient-to-r from-[#031714] to-[#c61569]]
      w-44 h-9 text-slate-50 font-medium mt-2 `}

                  onClick={() => { setShowModalQuickView(true) }}
                >
                  <i className="las la-pencil-alt text-2xl text-left mr-2"></i>
                  <span className="block  text-sm font-medium "
                  >
                    Write a review
                  </span>

                </button>
              </div>
            </div>
          </div>
          :
          <>
            <div className="w-full px-3">
              <div className="flex justify-center  container relative space-y-8 my-8 lg:space-y-16 lg:my-12">
                <div className="flex justify-center w-full">

                  <span className="block text-xl font-bold flex justify-center w-full text-center">
                    No Reviews available
                  </span>


                </div>

              </div>
              <div className="flex w-full mt-4 items-center justify-center">
                <button
                  className={
                    `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-gradient-to-r from-[#031714] to-[#c61569]
      w-44 h-9 text-slate-50 font-medium mt-2 `}

                  onClick={() => { setShowModalQuickView(true) }}
                >
                  <i className="las la-pencil-alt text-2xl text-left mr-2"></i>
                  <span className="block  text-sm font-medium "
                  >
                    Write a review
                  </span>

                </button>
              </div>
            </div>
          </>
      }




      <ModalQuickView
        show={showModalQuickView}
        onCloseModalQuickView={() => setShowModalQuickView(false)}
        proId={proId}
      />
    </>

  );
};

export default SectionSliderCategories;
