import React, { FC, useContext } from "react";
import NcImage from "shared/NcImage/NcImage";
import { Link, NavLink, useHistory } from "react-router-dom";
import ButtonCircle from "shared/Button/ButtonCircle";
import Company1 from "images/avatars/3.png";
import { StarIcon } from "@heroicons/react/24/solid";
import Rating from '@mui/material/Rating';
import LikeButton from "components/LikeButton";
import { IMAGE_SERVER_URL_DEV } from "Constant/System";
import EmptyIMG from "images/empty.png";
import { addToWishlist } from "Services/API/Post";
import jwtDecode from "jwt-decode";
import WishContext from "Context/WishContext";
import toast from "react-hot-toast";
import { Transition } from "@headlessui/react";
import Prices from "components/Prices";
import UserWishList from "Constant/WishList";
import { addToast } from "shared/Toast";
import { DeleteWishlist } from "Services/API/Delete";
import _ from "lodash";
import ClImg from "images/cleaning/1.png"
import parse from 'html-react-parser';


export interface CardCategory2Props {
  className?: string;
  ratioClass?: string;
  bgClass?: string;
  featuredImage?: string;
  name?: string;
  desc?: string;
  data?: any;
}

const CardCategory2: FC<CardCategory2Props> = ({
  className = "",
  ratioClass = "aspect-w-1 aspect-h-1",
  bgClass = "bg-orange-50",
  featuredImage = ".",
  name,
  desc,
  data,
}) => {

  const history = useHistory();

  const { wish, setWish } = useContext(WishContext);

  const renderProductCartOnNotify = ({ size }: { size?: string }) => {


    return (
      <div className="flex ">
        <div className="h-24 w-20 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
          <img
            src={
              data.images && JSON.parse(data.images).length > 0 ?

                `${IMAGE_SERVER_URL_DEV}${JSON.parse(data.images).filter((item: { thumbnail: boolean; }) => item?.thumbnail === true)[0]?.path}` :

                ""
            }
            alt={data.productName}
            className="h-full w-full object-cover object-center"
          />
        </div>

        <div className="ml-4 flex flex-1 flex-col">
          <div>
            <div className="flex justify-between ">
              <div>
                <h3 className="text-base font-medium ">{data.productName}</h3>
                {/* <p className="mt-1 text-sm text-slate-500 dark:text-slate-400">
                  <span>
                    {variants ? variants[variantActive].name : `Natural`}
                  </span>
                  <span className="mx-2 border-l border-slate-200 dark:border-slate-700 h-4"></span>
                  <span>{size || "XL"}</span>
                </p> */}
              </div>
              <Prices price={data.unitprice} className="mt-0.5" />
            </div>
          </div>
          <div className="flex flex-1 items-end justify-between text-sm">
            {/* <p className="text-gray-500 dark:text-slate-400">Qty 1</p> */}

            <div className="flex">
              <a href="/account-savelists">
                <button
                  type="button"
                  className="font-medium text-primary-6000 dark:text-primary-500 "
                >
                  View Wishlist
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  };


  const notifyAddToWish = () => {


    const getToken = localStorage.getItem(`${window.location.hostname}.login`);

    // if (!getToken) {
    //   history.push("/login");
    //   window.scroll(0, 0);
    //   return true;
    // }

    // const tken = JSON.parse(getToken);
    // const userData: any = jwtDecode(tken.data);

    let tempId: any = ""

    let userId: any = ""

    if (localStorage.getItem("tempUser")) {
      tempId = localStorage.getItem("tempUser")
    }

    console.log("temp>>>>", tempId)

    if (getToken) {

      const { data } = JSON.parse(getToken);
      const { id }: any = jwtDecode(data);

      userId = id

    }

    let error = false;
    let cartDetails = {
      productId: data.id.toString(),
      userId: userId ? userId : tempId,
      qty: 1,
      // color: selectedProductColor,
      // size: selectedProductPrice,
      fileType: "",
      image: "",
    };

    console.log("data>>>>", cartDetails)


    if (!(_.map(wish, "id").includes(data.id))) {
      addToWishlist(cartDetails)
        .then((data) => {
          toast.custom(
            (t) => (
              <Transition
                appear
                show={t.visible}
                className="p-4 max-w-md w-full bg-white dark:bg-slate-800 shadow-lg rounded-2xl pointer-events-auto ring-1 ring-black/5 dark:ring-white/10 text-slate-900 dark:text-slate-200"
                enter="transition-all duration-150"
                enterFrom="opacity-0 translate-x-20"
                enterTo="opacity-100 translate-x-0"
                leave="transition-all duration-150"
                leaveFrom="opacity-100 translate-x-0"
                leaveTo="opacity-0 translate-x-20"
              >
                <p className="block text-base font-semibold leading-none">
                  Added to Wishlist !
                </p>
                <div className="border-t border-slate-200 dark:border-slate-700 my-4" />
                {renderProductCartOnNotify({ size: "" })}
              </Transition>
            ),
            { position: "top-right", id: "nc-product-notify", duration: 3000 }
          );
          // setlopen(false);
          // addToast(`${product?.productName} Added to Cart`, { appearance: "success", autoDismiss: true });
          // toast.custom(
          //   (t) => (
          //     <NotifyAddTocart
          //       productImage={`${IMAGE_SERVER_URL_DEV}${JSON.parse(images).filter((item: { thumbnail: boolean; }) => item?.thumbnail === true)[0]?.path}`}
          //       qualitySelected={1}
          //       show={t.visible}
          //       sizeSelected={""}
          //       variantActive={variantActive}
          //       selectedProductColor={""}
          //       selectedProduct={{productName:productName,unitPrice:unitprice}}
          //     />
          //   ),
          //   { position: "top-right", id: "nc-product-notify", duration: 3000 }
          // );

          UserWishList().then((res) => {
            console.log("count res-------->", res);
            setWish(res);




          });
          // this.setState({
          //   color2: "#645bff",
          //   alertClass: "success",
          //   msg: `${this.state.productData?.displayName} Added to Cart`,
          //   open_toast: true,
          // });
        })
        .catch((err) => {
          // setlopen(false);
          addToast(`${err.response.data.message}`, "warning");
          // this.setState({
          //   color2: "#645bff",
          //   alertClass: "success",
          //   msg: `${this.state.productData?.displayName} product add failed`,
          //   open_toast: true,
          // });
        });

    } else {
      DeleteWishlist(wish.filter((item: any) => item?.id === data.id)[0]?.wishlistId)
        .then((res) => {
          toast.custom(
            (t) => (
              <Transition
                appear
                show={t.visible}
                className="p-4 max-w-md w-full bg-white dark:bg-slate-800 shadow-lg rounded-2xl pointer-events-auto ring-1 ring-black/5 dark:ring-white/10 text-slate-900 dark:text-slate-200"
                enter="transition-all duration-150"
                enterFrom="opacity-0 translate-x-20"
                enterTo="opacity-100 translate-x-0"
                leave="transition-all duration-150"
                leaveFrom="opacity-100 translate-x-0"
                leaveTo="opacity-0 translate-x-20"
              >
                <p className="block text-base font-semibold leading-none">
                  Removed from Wishlist !
                </p>
                <div className="border-t border-slate-200 dark:border-slate-700 my-4" />
                {renderProductCartOnNotify({ size: "" })}
              </Transition>
            ),
            { position: "top-right", id: "nc-product-notify", duration: 3000 }
          );
          UserWishList().then((res) => {
            console.log("count res-------->", res);
            setWish(res);




          });
          //  this.setState(
          //    {
          //      open_toast: true,
          //      alertClass: "error",
          //      msg: `${data.displayName} Removed`,
          //      color: "#635bff",
          //    },
          //    () => this.getAllCart()
          //  );
        })
        .catch((error) =>
          console.error("delete cart item error", error)
        );

    }






  };


  return (
    // <NavLink to={"/product-detail/16"}>
    <div

      className={`${className} flex justify-center w-full h-full`}
    // data-nc-id="CardCategory2"
    >





      <div className="w-full h-full bg-[#FAFAFA] rounded-b-xl pb-4">

        <div className="relative w-full h-full">



          <div className="w-full h-44 object-fill bg-cover bg-center rounded-t-xl"
            style={{
              // backgroundImage: `url(${Cover})`
              backgroundImage: data?.image ? `url(${IMAGE_SERVER_URL_DEV}${data?.image})` : `url(${EmptyIMG})`

            }}
          >

          </div>
          <div className="flex items-center justify-center w-full px-3 pt-4">

            <div className="">
              {/* <NavLink to={`/product-detail/${data.productId}`}> */}
              <h2 className="text-sm text-[#003D35] dark:text-neutral-100 font-semibold flex justify-center text-center">
                {/* 3M Stainless Steel Cleaner And Polish */}
                {data.title}
              </h2>

              {/* <span className="flex items-center text-sm text-neutral-500 dark:text-neutral-400 mt-2">
                <Rating name="size-small" defaultValue={4} size="small" className="" readOnly />
                <div className="flex items-center space-x-0.5  text-yellow-500 mr-2">
                <StarIcon className="w-3 h-3" />
                <StarIcon className="w-3 h-3" />
                <StarIcon className="w-3 h-3" />
                <StarIcon className="w-3 h-3" />
                <StarIcon className="w-3 h-3" />
              </div>

              </span> */}
              <div className="w-full flex justify-center text-center items-center mt-4">

                <span className="text-xs font-normal prose prose-xs  w-full dark:prose-invert text-[#003D35]">

                  {
                    parse(data?.description)
                  }


                </span>


              </div>



            </div>



          </div>
          <div className="flex my-5 sm:my-8 lg:my-2">

          </div>

          <div className="sm:absolute sm:bottom-0 w-full">
            <div className="px-5 flex items-center justify-center">
              <button
                className={
                  `ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-gradient-to-r from-[#031714] to-[#c61569] 
        w-full h-9 text-slate-50 font-medium cursor-pointer`}

                onClick={() => {
                  // if (!user) {
                  //   router.push("/login");
                  //   return;
                  // }

                  // setShowModalQuickView(true);
                }}

              >
                {/* <i className="lab la-telegram-plane text-xl text-left mr-2"></i> */}
                <span className="block  text-xs font-medium">
                  Inquire Details
                </span>

              </button>
            </div>

          </div>

        </div>



      </div>

    </div>




  );
};

export default CardCategory2;
