import Button, { ButtonProps } from "shared/Button/Button";
import React from "react";

export interface ButtonPrimaryProps3 extends ButtonProps {}

const ButtonPrimary3: React.FC<ButtonPrimaryProps3> = ({
  className = "",
  ...args
}) => {
  return (
    <Button
    className={`ttnc-ButtonPrimary w-full md:w-auto text-[12.5px] disabled:bg-opacity-90 bg-[#fcfcfc] border-[0.5px] border-[#c61569] dark:bg-slate-100 text-[#c61569] dark:text-slate-800 py-4 min-w-[175px] ${className}`}
    {...args}
    />
  );
};

export default ButtonPrimary3; 