import { Dialog, Transition } from "@headlessui/react";
import React, { FC, Fragment, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import ProductQuickView from "./ProductQuickView";
import QuoteForm from "./QuoteForm";
import Blogs from "containers/PageProfile/Blogs";

import ModalQuote from "./ModalQuote";
import { AppliedJobs } from "Services/API/Get";
import WishContext from "Context/WishContext";
import _ from "lodash";
import { t } from "i18next";

export interface ModalQuickViewProps {
  show: boolean;
  onCloseModalQuickView: () => void;
  jobId?: any;
  type?: any;
  selectedAdds?: any;
  shop?: any;
  setselectedAdds?: any;
  form?: any;
  reply?: any;
}

const ModalQuickView: FC<ModalQuickViewProps> = ({
  show,
  onCloseModalQuickView,
  jobId,
  type = "quote",
  selectedAdds = [],
  shop = false,
  setselectedAdds,
  form = false,
  reply = false
}) => {
  console.log(">>>>", jobId);
  const location = useLocation();

  const [showModalQuickView, setShowModalQuickView] = React.useState(false);

  const { applied, setapplied } = useContext(WishContext);

  useEffect(() => {
    AppliedJobs({
      jobType: JSON.stringify([])
    })
      .then((res) => {
        console.log(">>>>>>>jobs", res.jobId);
        setapplied(res.jobId.result);
      })
      .catch((error) => {
        // setlopen(false);
        setapplied([]);
        console.log("Error", error.response);

        // setOpenToast(true);
        // setColor(COLORS.Orange);
        // setAlertClass("error");
        // setMsg(error.response.jobId.message);
      });
  }, [showModalQuickView]);

  const getState = (item?: any) => {
    if (item === "Victoria") {
      return "VIC";
    } else if (item === "New South Wales") {
      return "NSW";
    } else if (item === "Western Australia") {
      return "WA";
    } else if (item === "Queensland") {
      return "QLD";
    } else if (item === "Tasmania") {
      return "TAS";
    } else if (item === "South Australia") {
      return "SA";
    } else if (item === "Northern Territory") {
      return "NT";
    } else if (item === "Australian Capital Territory") {
      return "ACT";
    }
  };

  return (
    <>
      <ModalQuote
        show={showModalQuickView}
        onCloseModalQuickView={() => setShowModalQuickView(false)}
        jobId={jobId}
        type={"job"}
        setselectedAdds={setselectedAdds}
      />

      <Transition appear show={show} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-[999]"
          onClose={onCloseModalQuickView}
        >
          <div className="flex items-center md:items-center justify-center md:h-full text-center md:px-4 g:mt-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black/40 dark:bg-black/70" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="relative inline-flex xl:py-8 w-full max-w-[700px] max-h-full h-screen sm:h-fit">
                <div
                  className="flex-1 flex overflow-hidden max-h-full py-8 w-full text-left align-middle transition-all transform lg:rounded-lg bg-[#F4F4F4]
              dark:bg-neutral-900 dark:border dark:border-slate-700 dark:text-slate-100 shadow-xl px-4 sm:px-8"
                >
                  <span className="absolute right-3 top-3 z-50">
                    <ButtonClose onClick={onCloseModalQuickView} />
                  </span>

                  <div className="flex-1 overflow-y-auto rounded-xl">
                    {/* <QuoteForm onCloseModalQuickView={onCloseModalQuickView} jobId={jobId} type={type} selectedAdds={selectedAdds} shop={shop} setselectedAdds={setselectedAdds}/> */}

                    <div className="mt-0 lg:px-5">
                      <div className="flex justify-between w-full">
                        <div className="flex flex-col">
                          <h2
                          // className="text-sm sm:text-sm text-slate-900 dark:text-neutral-100 font-bold h-6"
                          >
                            {/* {
                              jobId ? jobId?.name?.slice(0, 21) : "Company description"
                            }

                            {
                              jobId?.name?.length >= 21 ? "..." : ""
                            } */}

                            {/* {jobId?.gender === "Male" ? "Looking for Bride" : (jobId?.gender === "Female" ? "Looking for Groom" : (t("langKey") === "Si" && jobId?.gender === "සහකරු" ? "මනාලියෙකු සොයයි" : "මනමාලයෙකු සොයයි"))} */}

                            {/* {
    jobId ? jobId?.title : "Ad Title should be here appear here "
  } */}
                          </h2>

                          <span
                            className={`text-[#003D35] flex items-center justify-start ${
                              t("langKey") === "Si" ? "text-xl" : "text-xl"
                            } font-semibold mt-2`}
                          >
                            {jobId?.gender === "Male"
                              ? "Groom"
                              : jobId?.gender === "Female"
                              ? "Bride"
                              : jobId?.gender}
                          </span>

                          <span className="text-slate-900 flex items-center justify-start text-xs font-semibold mt-2">
                            {jobId?.occupation}
                          </span>

                          <span className="text-[#00B88B] flex items-center justify-start text-xs font-medium mt-2">
                            {/* <i className="las la-eye text-lg text-left mr-1"></i>
                     
                      {jobId?.viewsCount} */}
                            <i className="las la-map-marker-alt text-base text-left mr-1 text-[#00B88B]"></i>
                            {/* {jobId?.city} */}
                            {jobId?.currentResidesCountry === "Sri Lanka" ? (
                              <>{jobId?.city}</>
                            ) : (
                              <>{jobId?.currentResidesCountry}</>
                            )}
                          </span>
                        </div>
                      </div>

                      <div className="border border-white border-opacity-50 w-full my-4"></div>

                      <div className="hidden md:grid grid-cols-2">
                        <div
                          className={`text-slate-900 mt-4 ${
                            t("langKey") === "Si"
                              ? "text-xs"
                              : "text-xs lg:text-xs"
                          } font-normal `}
                        >
                          {/* <div className="flex space-x-6"> */}

                          {/* <div className="flex flex-col space-y-2">
                              <span>{t("fType")}</span>
                              <span>{t("fAge")}</span>
                              <span>{t("fHei")}</span>
                              <span>{t("fOcc")}</span>
                              <span>{t("fCast")}</span>
                              <span>{t("fHor")}</span>
                              <span>{t("fRace")}</span>
                              <span>{t("fRel")}</span>

                            </div>



                            <div className="flex flex-col space-y-2">
                              <span>:</span>
                              <span>:</span>
                              <span>:</span>
                              <span>:</span>
                              <span>:</span>
                              <span>:</span>
                              <span>:</span>
                              <span>:</span>

                            </div>

                            <div className="flex flex-col space-y-2">
                              <span > {jobId?.gender === "Male" ? "Groom" : (jobId?.gender === "Female" ? "Bride" : jobId?.gender)} </span>
                              <span > {jobId?.age}</span>
                              <span > {jobId?.height}</span>
                              <span > {jobId?.occupation}</span>
                              <span > {jobId?.cast}</span>
                              <span > {jobId?.horoscopStatus}</span>
                              <span > {jobId?.race}</span>
                              <span > {jobId?.religion}</span>

                            </div> */}

                          <div className="grid grid-cols-2 gap-y-2">
                            {
                              // reply
                              false ? (
                                <>
                                  <span>{t("repOwnerName")}</span>
                                  <span> : &ensp; {jobId?.ownerName}</span>

                                  <span>{t("repOwnerType")}</span>
                                  <span> : &ensp; {jobId?.accType}</span>

                                  <span>{t("fiContact")}</span>
                                  <span> : &ensp; {jobId?.ownerContact}</span>

                                  <span>{t("fType")}</span>
                                  <span>
                                    {" "}
                                    : &ensp;{" "}
                                    {jobId?.gender === "Male"
                                      ? "Groom"
                                      : jobId?.gender === "Female"
                                      ? "Bride"
                                      : jobId?.gender}{" "}
                                  </span>

                                  <span>{t("fAge")}</span>
                                  <span> : &ensp; {jobId?.age}</span>

                                  <span>{t("fHei")}</span>
                                  <span> : &ensp; {jobId?.height}</span>

                                  {/* <span>{t("fOcc")}</span>
                                  <span > : &ensp; {jobId?.occupation}</span> */}

                                  <span>{t("fCast")}</span>
                                  <span> : &ensp; {jobId?.cast}</span>

                                  <span>{t("fHor")}</span>
                                  <span> : &ensp; {jobId?.horoscopStatus}</span>

                                  {/* <span>{t("fRace")}</span>
                                  <span > : &ensp; {jobId?.race}</span>

                                  <span>{t("fRel")}</span>
                                  <span > : &ensp; {jobId?.religion}</span> */}
                                </>
                              ) : (
                                <>
                                  <span className="text-[#003D35] font-semibold">
                                    {t("fType")}
                                  </span>
                                  <span>
                                    {" "}
                                    : &ensp;{" "}
                                    {jobId?.gender === "Male"
                                      ? "Groom"
                                      : jobId?.gender === "Female"
                                      ? "Bride"
                                      : jobId?.gender}{" "}
                                  </span>

                                  <span className="text-[#003D35] font-semibold">
                                    {t("fAge")}
                                  </span>
                                  <span> : &ensp; {jobId?.age}</span>

                                  <span className="text-[#003D35] font-semibold">
                                    {t("fHei")}
                                  </span>
                                  <span> : &ensp; {jobId?.height}</span>

                                  {/* <span className="text-[#003D35] font-semibold">{t("fOcc")}</span>
                                  <span > : &ensp; {jobId?.occupation}</span> */}

                                  <span className="text-[#003D35] font-semibold">
                                    {t("fCast")}
                                  </span>
                                  <span> : &ensp; {jobId?.cast}</span>

                                  <span className="text-[#003D35] font-semibold">
                                    {t("fHor")}
                                  </span>
                                  <span> : &ensp; {jobId?.horoscopStatus}</span>

                                  <span className="text-[#003D35] font-semibold">
                                    {t("fRace")}
                                  </span>
                                  <span> : &ensp; {jobId?.race}</span>
                                </>
                              )
                            }
                          </div>

                          {/* </div> */}
                        </div>

                        <div
                          className={`text-slate-900 mt-4 ${
                            t("langKey") === "Si"
                              ? "text-xs"
                              : "text-xs lg:text-xs"
                          } font-normal`}
                        >
                          {/* <div className="flex space-x-6"> */}

                          {/* <div className="flex flex-col space-y-2">
                              <span>{t("fEdu")}</span>
                              <span>{t("fMarr")}</span>
                              {jobId?.district && <span>{t("fDis")}</span>}
                              {jobId?.city && <span>{t("fCity")}</span>}
                              <span>{t("fCountry")}</span>
                              <span>{t("fiContact")}</span>
                              <span>{t("fWtsap")}</span>
                              <span>{t("signUpEmail")}</span>

                            </div>



                            <div className="flex flex-col space-y-2">
                              <span>:</span>
                              <span>:</span>
                              {jobId?.district && <span>:</span>}
                              {jobId?.city && <span>:</span>}
                              <span>:</span>
                              <span>:</span>
                              <span>:</span>
                              <span>:</span>

                            </div>

                            <div className="flex flex-col space-y-2">
                              <span > {jobId?.educationStatus} </span>
                              <span > {jobId?.marriageStatus}</span>
                              {jobId?.district && <span > {jobId?.district}</span>}
                              {jobId?.city && <span > {jobId?.city}</span>}
                              <span > {jobId?.currentResidesCountry}</span>
                              <span > {jobId?.contactNo}</span>
                              <span > {jobId?.whatsAppNo}</span>
                              <span > {jobId?.mainEmail}</span>

                            </div> */}

                          <div className="grid grid-cols-2 gap-y-2 ">
                            {
                              // reply
                              false ? (
                                <>
                                  <span>{t("fRace")}</span>
                                  <span> : &ensp; {jobId?.race}</span>

                                  <span>{t("fRel")}</span>
                                  <span> : &ensp; {jobId?.religion}</span>

                                  <span>{t("fEdu")}</span>
                                  <span>
                                    {" "}
                                    : &ensp; {jobId?.educationStatus}{" "}
                                  </span>

                                  <span>{t("fMarr")}</span>
                                  <span> : &ensp; {jobId?.marriageStatus}</span>

                                  {jobId?.district && <span>{t("fDis")}</span>}
                                  {jobId?.district && (
                                    <span> : &ensp; {jobId?.district}</span>
                                  )}

                                  {/* {jobId?.city && <span>{t("fCity")}</span>}
                                  {jobId?.city && <span > : &ensp; {jobId?.city}</span>} */}

                                  <span>{t("fCountryTwo")}</span>
                                  <span>
                                    {" "}
                                    : &ensp; {jobId?.currentResidesCountry}
                                  </span>

                                  {/* {jobId?.contactNo && <span>{t("fiContact")}</span>}
                                  {jobId?.contactNo && <span > : &ensp; {jobId?.contactNo}</span>}

                                  {jobId?.whatsAppNo && <span>{t("fWtsap")}</span>}
                                  {jobId?.whatsAppNo && <span > : &ensp; {jobId?.whatsAppNo}</span>} */}

                                  {jobId?.mainEmail && (
                                    <>
                                      <span>{t("signUpEmail")}</span>
                                      <span> : &ensp; {jobId?.mainEmail}</span>
                                    </>
                                  )}
                                </>
                              ) : (
                                <>
                                  <span className="text-[#003D35] font-semibold">
                                    {t("fEdu")}
                                  </span>
                                  <span>
                                    {" "}
                                    : &ensp; {jobId?.educationStatus}{" "}
                                  </span>

                                  <span className="text-[#003D35] font-semibold">
                                    {t("fMarr")}
                                  </span>
                                  <span> : &ensp; {jobId?.marriageStatus}</span>

                                  {jobId?.district && (
                                    <span className="text-[#003D35] font-semibold">
                                      {t("fDis")}
                                    </span>
                                  )}
                                  {jobId?.district && (
                                    <span> : &ensp; {jobId?.district}</span>
                                  )}

                                  {/* {jobId?.city && <span className="text-[#003D35] font-semibold">{t("fCity")}</span>}
                                  {jobId?.city && <span > : &ensp; {jobId?.city}</span>} */}

                                  <span className="text-[#003D35] font-semibold">
                                    {t("fCountryTwo")}
                                  </span>
                                  <span>
                                    {" "}
                                    : &ensp; {jobId?.currentResidesCountry}
                                  </span>

                                  <span className="text-[#003D35] font-semibold">
                                    {t("fRel")}
                                  </span>
                                  <span> : &ensp; {jobId?.religion}</span>

                                  {/* {jobId?.contactNo && <span>{t("fiContact")}</span>}
                                  {jobId?.contactNo && <span > : &ensp; {jobId?.contactNo}</span>}

                                  {jobId?.whatsAppNo && <span>{t("fWtsap")}</span>}
                                  {jobId?.whatsAppNo && <span > : &ensp; {jobId?.whatsAppNo}</span>} */}

                                  {/* {
                                    jobId?.mainEmail &&
                                    <>
                                      <span className="text-[#003D35] font-semibold">{t("signUpEmail")}</span>
                                      <span className="whitespace-nowrap"> : &ensp; {jobId?.mainEmail}</span>
                                    </>

                                  } */}
                                </>
                              )
                            }
                          </div>

                          {/* </div> */}
                        </div>
                      </div>

                      {/* <div className="lg:hidden flex flex-col lg:flex-row justify-between">

                        <div className="text-slate-900 mt-4 text-xs lg:text-sm font-normal">
                          <div className="flex space-x-6 ">

                            <div className="flex flex-col space-y-2">
                              <span>{t("fType")}</span>
                              <span>{t("fAge")}</span>
                              <span>{t("fHei")}</span>
                              <span>{t("fOcc")}</span>
                              <span>{t("fCast")}</span>
                              <span>{t("fHor")}</span>
                              <span>{t("fRace")}</span>
                              <span>{t("fRel")}</span>
                              <span>{t("fEdu")}</span>
                              <span>{t("fMarr")}</span>
                              {jobId?.district && <span>{t("fDis")}</span>}
                              {jobId?.city && <span>{t("fCity")}</span>}
                              <span>{t("fCountry")}</span>
                              <span>{t("fiContact")}</span>
                              <span>{t("fWtsap")}</span>
                              <span>{t("signUpEmail")}</span>

                            </div>



                            <div className="flex flex-col space-y-2">
                              <span>:</span>
                              <span>:</span>
                              <span>:</span>
                              <span>:</span>
                              <span>:</span>
                              <span>:</span>
                              <span>:</span>
                              <span>:</span>
                              <span>:</span>
                              <span>:</span>
                              {jobId?.district && <span>:</span>}
                              {jobId?.city && <span>:</span>}
                              <span>:</span>
                              <span>:</span>
                              <span>:</span>
                              <span>:</span>


                            </div>

                            <div className="flex flex-col space-y-2">
                              <span > {jobId?.gender === "Male" ? "Groom" : (jobId?.gender === "Female" ? "Bride" : jobId?.gender)} </span>
                              <span > {jobId?.age}</span>
                              <span > {jobId?.height}</span>
                              <span > {jobId?.occupation}</span>
                              <span > {jobId?.cast}</span>
                              <span > {jobId?.horoscopStatus}</span>
                              <span > {jobId?.race}</span>
                              <span > {jobId?.religion}</span>
                              <span > {jobId?.educationStatus} </span>
                              <span > {jobId?.marriageStatus}</span>
                              {jobId?.district && <span > {jobId?.district}</span>}
                              {jobId?.city && <span > {jobId?.city}</span>}
                              <span > {jobId?.currentResidesCountry}</span>
                              <span > {jobId?.contactNo}</span>
                              <span > {jobId?.whatsAppNo}</span>
                              <span > {jobId?.mainEmail}</span>

                            </div>

                          </div>



                        </div>

                      </div> */}

                      <div className="md:hidden flex flex-col lg:flex-row justify-between">
                        <div className="text-slate-900 mt-4 text-xs lg:text-sm font-normal">
                          <div className="grid grid-cols-2 gap-y-2">
                            {
                              // reply
                              false && (
                                <>
                                  <span>{t("repOwnerName")}</span>
                                  <span> : &ensp; {jobId?.ownerName}</span>

                                  <span>{t("repOwnerType")}</span>
                                  <span> : &ensp; {jobId?.accType}</span>

                                  <span>{t("fiContact")}</span>
                                  <span> : &ensp; {jobId?.ownerContact}</span>
                                </>
                              )
                            }
                            <span className="text-[#003D35] font-semibold">
                              {t("fType")}
                            </span>
                            <span>
                              {" "}
                              : &ensp;{" "}
                              {jobId?.gender === "Male"
                                ? "Groom"
                                : jobId?.gender === "Female"
                                ? "Bride"
                                : jobId?.gender}{" "}
                            </span>

                            <span className="text-[#003D35] font-semibold">
                              {t("fAge")}
                            </span>
                            <span> : &ensp; {jobId?.age}</span>

                            <span className="text-[#003D35] font-semibold">
                              {t("fHei")}
                            </span>
                            <span> : &ensp; {jobId?.height}</span>

                            {/* <span className="text-[#003D35] font-semibold">{t("fOcc")}</span>
                            <span > : &ensp; {jobId?.occupation}</span> */}

                            <span className="text-[#003D35] font-semibold">
                              {t("fCast")}
                            </span>
                            <span> : &ensp; {jobId?.cast}</span>

                            <span className="text-[#003D35] font-semibold">
                              {t("fHor")}
                            </span>
                            <span> : &ensp; {jobId?.horoscopStatus}</span>

                            <span className="text-[#003D35] font-semibold">
                              {t("fRace")}
                            </span>
                            <span> : &ensp; {jobId?.race}</span>

                            <span className="text-[#003D35] font-semibold">
                              {t("fRel")}
                            </span>
                            <span> : &ensp; {jobId?.religion}</span>

                            <span className="text-[#003D35] font-semibold">
                              {t("fEdu")}
                            </span>
                            <span> : &ensp; {jobId?.educationStatus} </span>

                            <span className="text-[#003D35] font-semibold">
                              {t("fMarr")}
                            </span>
                            <span> : &ensp; {jobId?.marriageStatus}</span>

                            {jobId?.district && (
                              <span className="text-[#003D35] font-semibold">
                                {t("fDis")}
                              </span>
                            )}
                            {jobId?.district && (
                              <span> : &ensp; {jobId?.district}</span>
                            )}

                            {/* {jobId?.city && <span className="text-[#003D35] font-semibold">{t("fCity")}</span>}
                            {jobId?.city && <span > : &ensp; {jobId?.city}</span>} */}

                            <span className="text-[#003D35] font-semibold">
                              {t("fCountryTwo")}
                            </span>
                            <span>
                              {" "}
                              : &ensp; {jobId?.currentResidesCountry}
                            </span>

                            {/* {jobId?.contactNo && <span>{t("fiContact")}</span>}
                            {jobId?.contactNo && <span > : &ensp; {jobId?.contactNo}</span>}

                            {jobId?.whatsAppNo && <span>{t("fWtsap")}</span>}
                            {jobId?.whatsAppNo && <span > : &ensp; {jobId?.whatsAppNo}</span>} */}

                            {/* {
                              jobId?.mainEmail &&
                              <>
                                <span className="text-[#003D35] font-semibold">{t("signUpEmail")}</span>
                                <span className="whitespace-nowrap"> : &ensp; {jobId?.mainEmail}</span>
                              </>

                            } */}

                            {/* <span className="text-[#003D35] font-semibold">{t("signUpEmail")}</span>
                            <span > : &ensp; {jobId?.mainEmail}</span> */}
                          </div>

                          <div className="flex space-x-4">
                            {/* <div className="flex flex-col space-y-2">
            <span>{t("fType")}</span>
            <span>{t("fAge")}</span>
            <span>{t("fHei")}</span>
            <span>{t("fOcc")}</span>
            <span>{t("fCast")}</span>
            <span>{t("fHor")}</span>
            <span>{t("fRace")}</span>
            <span>{t("fRel")}</span>
            <span>{t("fEdu")}</span>
            <span>{t("fMarr")}</span>
            {jobId?.district && <span>{t("fDis")}</span>}
            {jobId?.city && <span>{t("fCity")}</span>}
            <span>{t("fCountry")}</span>
            <span>{t("fiContac")}</span>
            <span>{t("fWtsap")}</span>
            <span>{t("signUpEmail")}</span>

        </div>



        <div className="flex flex-col space-y-2">
            <span>:</span>
            <span>:</span>
            <span>:</span>
            <span>:</span>
            <span>:</span>
            <span>:</span>
            <span>:</span>
            <span>:</span>
            <span>:</span>
            <span>:</span>
            {jobId?.district && <span>:</span>}
            {jobId?.city && <span>:</span>}
            <span>:</span>
            <span>:</span>
            <span>:</span>
            <span>:</span>


        </div> */}

                            {/* <div className="flex flex-col space-y-2">
            <span > {jobId?.gender === "Male" ? "Groom" : (jobId?.gender === "Female" ? "Bride" : jobId?.gender)} </span>
            <span > {jobId?.age}</span>
            <span > {jobId?.height}</span>
            <span > {jobId?.occupation}</span>
            <span > {jobId?.cast}</span>
            <span > {jobId?.horoscopStatus}</span>
            <span > {jobId?.race}</span>
            <span > {jobId?.religion}</span>
            <span > {jobId?.educationStatus} </span>
            <span > {jobId?.marriageStatus}</span>
            {jobId?.district && <span > {jobId?.district}</span>}
            {jobId?.city && <span > {jobId?.city}</span>}
            <span > {jobId?.currentResidesCountry}</span>
            <span > {jobId?.contactNo}</span>
            <span > {jobId?.whatsAppNo}</span>
            <span > {jobId?.mainEmail}</span>

        </div> */}
                          </div>
                        </div>
                      </div>

                      {jobId?.familyBackground && (
                        <div className="flex flex-col text-slate-900 mt-8 text-md font-normal">
                          <span className="font-semibold">{t("fFam")}</span>

                          <span className="text-xs mt-3">
                            {jobId?.familyBackground}
                          </span>
                        </div>
                      )}

                      {reply && (
                        <div className="w-full bg-[#2D867B] py-4 px-6 rounded-lg mt-14">
                          <span className="font-bold text-white text-sm">
                            Contact Details{" "}
                          </span>

                          <div className="text-white mt-4 text-xs lg:text-sm font-normal">
                            <div className="grid grid-cols-2 gap-y-2 lg:pr-0 pb-4 w-fit">
                              <>
                                <span className="mr-2">
                                  {t("repOwnerName")}
                                </span>
                                <span> : &nbsp; {jobId?.ownerName}</span>

                                <span className="mr-2">
                                  {t("repOwnerType")}
                                </span>
                                <span> : &nbsp; {jobId?.accType} </span>

                                <span className="mr-2">
                                  {t("repOwnerContact")}
                                </span>
                                <span> : &nbsp; {jobId?.ownerContact}</span>
                              </>
                            </div>

                            {/* </div> */}
                          </div>
                        </div>
                      )}

                      <div className="mt-10 flex justify-end">
                        {!form && (
                          <>
                            {_.map(applied, "adId").includes(jobId?.id) ===
                            true ? (
                              <button
                                className={`ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-[#d63a3a] 
         w-full lg:w-[200px] h-9 text-slate-50 font-medium mt-2 cursor-pointer`}
                              >
                                {/* <i className="lab la-telegram-plane text-xl text-left mr-2"></i> */}
                                <span className="block  text-xs font-medium">
                                  Already Applied
                                </span>
                              </button>
                            ) : (
                              <button
                                className={`ttnc-ButtonCircle rounded-md flex items-center justify-center !leading-none disabled:bg-opacity-70 bg-gradient-to-r from-[#031714] to-[#c61569] 
       w-full lg:w-[200px] h-9 text-slate-50 font-medium mt-2 cursor-pointer`}
                                onClick={() => {
                                  // if (!user) {
                                  //   router.push("/login");
                                  //   return;
                                  // }
                                  if (!form) {
                                    // onCloseModalQuickView();
                                    setShowModalQuickView(true);
                                  }
                                }}
                              >
                                {/* <i className="lab la-telegram-plane text-xl text-left mr-2"></i> */}
                                <span className="block  text-xs font-medium">
                                  {t("appDetails")}
                                </span>
                              </button>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ModalQuickView;
