import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import { Link } from "react-router-dom";
import ButtonCircle from "shared/Button/ButtonCircle";
import Company1 from "images/avatars/Image-1.png";
import { StarIcon } from "@heroicons/react/24/solid";
import { Rating } from "@mui/material";
import { IMAGE_SERVER_URL_DEV } from "Constant/System";
import moment from "moment";

export interface CardCategory2Props {
  className?: string;
  ratioClass?: string;
  bgClass?: string;
  featuredImage?: string;
  name?: string;
  desc?: string;
  data?: any;
}

const CardCategory2: FC<CardCategory2Props> = ({
  className = "",
  ratioClass = "aspect-w-1 aspect-h-1",
  bgClass = "bg-orange-50",
  featuredImage = ".",
  name,
  desc,
  data
}) => {
  console.log("data: " + JSON.stringify(data, null, 2));

  return (
    <div
      className={`nc-CardCategory2 ${className} w-[350px] md:w-auto md:min-w-[350px] mx-10 md:mx-0 `}
      data-nc-id="CardCategory2"
    >
      <div
      // className={`flex-1 relative w-full h-0 rounded-2xl overflow-hidden  ${ratioClass}`}
      >
        <div className="  rounded-md md:m-10 shadow-2xl">
          <div className="mt-4">
            <div className=" aspect-square flex justify-center  rounded-t-md overflow-hidden">
              <img src={featuredImage} className="object-cover w-full" alt={name || "Image Description"} />
            </div>

            <div className="flex flex-col justify-center items-start w-full p-4">
              <h2 className="text-[20.9px] font-[Quattrocento] text-[#ff35b4] dark:text-neutral-100 font-light">
                {data ? data?.name : "Review Title"}
              </h2>

              <span className=" text-[#2f3840] text-[13.5px] italic mt-1 font-inter">
                {data ? data?.desc : "Review Title"}
              </span>
            </div>
          </div>

          {/* <img
            className="object-cover "
            src={featuredImage}

          /> */}
        </div>
        <div className="pt-0"></div>
        <span className="opacity-0 group-hover:opacity-100 absolute inset-0 bg-black bg-opacity-10 transition-opacity"></span>
      </div>
      {/* <div className="mt-5 flex-1 text-center">
        <h2 className="text-base sm:text-base text-neutral-900 dark:text-neutral-100 font-semibold">
          {name}
        </h2>
        <span className="block mt-0.5 sm:mt-1.5 text-sm text-neutral-500 dark:text-neutral-400">
          {desc}
        </span>
      </div> */}
    </div>
  );
};

export default CardCategory2;
